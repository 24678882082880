<template>
  <div class="reward-list">
    <div class="reward-item" v-for="reward in rewards" :key="reward.id">
      <div class="info flex row centered">
        <div class="icon flex centered">{{ getIcon(reward.reason) }}</div>
        <span class="name">{{ reward.reasonText }}</span>
      </div>
      <span class="reward flex centered" v-if="reward.amount >= 0">+{{ numberWithCommas(reward.amount) }} $HUG</span>
      <span class="reward flex centered" v-else>{{ numberWithCommas(reward.amount) }} $HUG</span>
    </div>
  </div>
</template>

<script>
import { numberWithCommas } from '../utility';
export default {
  name: 'RewardList',
  methods: {
    numberWithCommas,
    getIcon(reason) {
      if (reason === 'premium') {
        return '🌟'
      } else if (reason === 'activity') {
        return '📊'
      } else if (reason === 'hug' || reason === 'action_hug') {
        return '🫂'
      } else if (reason === 'subscription') {
        return '📢'
      } else if (reason === 'stars') {
        return '⭐️'
      } else if (reason === 'tasks') {
        return '📖'
      } else if (reason === 'daily') {
        return '🎁'
      } else if (reason === 'ton_daily') {
        return '🎁'
      } else if (reason === 'mountain_king') {
        return '👑'
      } else if (reason === 'levelup') {
        return '⬆️'
      } else if (reason === 'combo') {
        return '🍀'
      } else if (reason === 'gift') {
        return '🎁'
      }
    }
  },
  props: ['rewards']
}
</script>

<style scoped lang="scss">
.reward-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px 0;
  padding: 0 10px;

  @media (max-width: 386px) {
    padding: 0 5px;
  }

  .reward-item {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #EDEDED;
    }

    .info {
      justify-content: flex-start;
    }

    .name {
      margin-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 170px;
      white-space: nowrap;
      font-size: 18px;
      color: #4F4F4F;


      @media (max-width: 386px) {
        font-size: 14px;
      }
    }

    .reward {
      font-weight: 600;
      width: 100%;
      justify-content: flex-end;
      padding: 0 10px;

      @media (max-width: 386px) {
        font-size: 14px;
      }
    }
  }
}
</style>
