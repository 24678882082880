export function numberWithCommas(x) {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function vibrate() {
  try {
    const navigatorVibrate =
      navigator.vibrate ||
      navigator.webkitVibrate ||
      navigator.mozVibrate ||
      navigator.msVibrate;

    if (navigatorVibrate) {
      navigatorVibrate.call(navigator, [15]);
    } else {
      window.Telegram?.WebApp?.HapticFeedback.impactOccurred('light');
    }
  } catch (e) {
    console.log(e)
  }
}