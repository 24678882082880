export default {
  namespaced: true,

  state() {
    return {
      lastFeed: null
    }
  },
  getters: {

  },
  mutations: {
    setLastFeed(state, feed) {
      state.lastFeed = feed
    }
  },
  actions: {
  },
  modules: {
  }
}
