<template>
  <Vue3Lottie :animationData="animation" :height="160" :width="160" />
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import animation from '@/assets/images/trophy.json'

export default {
  components: {
    Vue3Lottie,
  },
  data() {
    return {
      animation,
    }
  },
}
</script>