<template>
  <div class="avatar flex centered" :style="{
    'background': avatarColor,
    'width': `${width}px`,
    'height': `${height}px`,
  }" :class="badge">
    {{ avatarIcon }}
    <img :src="`https://t.me/i/userpic/160/${telegramLogin}.jpg`" v-if="telegramLogin" />
    <span class="level flex centered" :class="levelBig ? 'big' : ''">{{ level }}</span>
  </div>
</template>

<script>
export default {
  name: "Avatar",
  props: ['avatarColor', 'avatarIcon', 'telegramLogin', 'level', 'width', 'height', 'levelBig'],
  computed: {
    badge() {
      if (this.between(this.level, 1, 5)) {
        return 'common'
      }
      if (this.between(this.level, 6, 10)) {
        return 'rare'
      }
      if (this.between(this.level, 11, 19)) {
        return 'epic'
      }
      if (this.between(this.level, 20, 20)) {
        return 'god'
      }

      return 'common'
    }
  },
  methods: {
    between(x, min, max) {
      return x >= min && x <= max;
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  width: 106px;
  height: 106px;
  border-radius: 50%;
  font-size: 1em;
  z-index: 102;
  position: relative;

  img {
    max-width: 100%;
    position: absolute;
    border-radius: 50%;
  }

  .level {
    position: absolute;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    width: 18px;
    height: 18px;
    bottom: -4px;
    right: 0;

    &.big {
      width: 36px;
      height: 36px;
      bottom: -8px;
      font-size: 18px;
    }
  }

  &.common {
    // border: 1px solid #a7a7a7;

    .level {
      background: #a7a7a7;
      font-weight: 400;
    }
  }

  &.rare {
    // border: 1px solid #007aff;

    .level {
      background: #007aff;
      font-weight: 500;
      box-shadow: 0 0 10px #007bff54;
    }
  }

  &.epic {
    // border: 2px solid #ff9a00;

    .level {
      background: #ff9a00;
      font-weight: 700;
      box-shadow: 0 0 10px #ff9a00;
    }
  }

  &.god {
    // border: 2px solid #ff9a00;

    .level {
      background: #e02d15;
      font-weight: 800;
      box-shadow: 0 0 10px #e02d15;
    }
  }
}
</style>