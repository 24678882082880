<template>
  <Vue3Lottie :animationData="animation" :height="120" :width="120" />
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import animation from '@/assets/images/hug.json'

export default {
  components: {
    Vue3Lottie,
  },
  data() {
    return {
      animation,
    }
  },
}
</script>