<template>
  <span class="name" :class="{ 'vip-name': isVip }">
    <img src="@/assets/images/yellow-star.svg" class="vip" v-if="isVip" />
    {{ username }}
  </span>
</template>

<script>
export default {
  name: "Name",
  props: ['isVip', 'username']
}
</script>

<style lang="scss" scoped>
.name {
  font-size: 16px;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
  white-space: nowrap;
  display: flex;
  align-items: center;

  &.vip-name {
    color: #fbc751;
    font-weight: 600;
  }

  img {
    width: 21px;
    margin-right: 3px;
  }
}
</style>