<template>
  <Modal class="guest-modal">
    <template v-if="guest">
      <Avatar :avatarColor="guest.avatarColor" :avatarIcon="guest.avatarIcon" :telegramLogin="guest.telegramLogin"
        :level="guest.level" levelBig="true" />

      <div class="modal-header">
        <h4 class="title">
          <Name :username="guest.username" :isVip="guest.isVip" />
        </h4>
        <div class="close centered" @click="$emit('closeModal')">X</div>
      </div>

      <div class="view-telegram" @click="viewTelegram" v-if="guest.telegramLogin" v-show="isTelegramHidden !== null">
        <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M23.1117 4.49449C23.4296 2.94472 21.9074 1.65683 20.4317 2.227L2.3425 9.21601C0.694517 9.85273 0.621087 12.1572 2.22518 12.8975L6.1645 14.7157L8.03849 21.2746C8.13583 21.6153 8.40618 21.8791 8.74917 21.968C9.09216 22.0568 9.45658 21.9576 9.70712 21.707L12.5938 18.8203L16.6375 21.8531C17.8113 22.7334 19.5019 22.0922 19.7967 20.6549L23.1117 4.49449ZM3.0633 11.0816L21.1525 4.0926L17.8375 20.2531L13.1 16.6999C12.7019 16.4013 12.1448 16.4409 11.7929 16.7928L10.5565 18.0292L10.928 15.9861L18.2071 8.70703C18.5614 8.35278 18.5988 7.79106 18.2947 7.39293C17.9906 6.99479 17.4389 6.88312 17.0039 7.13168L6.95124 12.876L3.0633 11.0816ZM8.17695 14.4791L8.78333 16.6015L9.01614 15.321C9.05253 15.1209 9.14908 14.9366 9.29291 14.7928L11.5128 12.573L8.17695 14.4791Z"
            fill="#0F0F0F" />
        </svg>
        View in telegram
      </div>

      <div class="actions">
        <Button class="action-btn hug-btn" color="blue" @on-click="() => giveHug()"
          v-if="$store.getters.isHugAvailable">
          <div class="hug-icon">🤗</div>
          Send a hug! (+{{ $store.getters.currentLevel.hugReward }} $HUG)
        </Button>
        <Button class="action-btn" color="black" v-else>
          Hug cooldown ({{ $store.getters.hugCooldown }})
        </Button>
      </div>
    </template>
    <template v-else>
      <span>Loading...</span>
    </template>
  </Modal>
</template>

<script>
import axios from 'axios'

import Modal from './Modal.vue'
import Button from '../Button.vue'
import Name from '../Name.vue';
import Avatar from '../Avatar.vue';

export default {
  name: 'GuestModal',
  components: { Modal, Button, Avatar, Name },
  methods: {
    viewTelegram() {
      if (!this.guest.telegramLogin) {
        return
      }

      window.Telegram.WebApp.openTelegramLink(`https://t.me/${this.guest.telegramLogin}`)
    },
    async fetchUserInfo() {
      const { data } = await axios.get(`/user/get-user-info/${this.$store.state.guest}`)
      this.guest = data.guest
    },
    async giveHug() {
      axios.post('/user/give-hug/' + (this.$store.state.guest)).then(({ data }) => {
        if (data) {
          this.$store.commit('getBalance')
          this.$store.commit('setLastHugAt', new Date())
        } else {
          this.$store.commit('showError', 'Try later')
        }
      }).catch(() => {
        this.$store.commit('showError', 'Try later')
      })
    }
  },
  data() {
    return {
      guest: null,
    }
  },
  mounted() {
    this.fetchUserInfo()
  }
}
</script>

<style lang="scss">
.guest-modal {
  .hug-btn {
    position: relative;
    margin-top: 10px !important;
    padding-top: 20px !important;

    .hug-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      position: absolute;
      left: calc(50% - 25px);
      top: -25px;
      background: #007AFF;
      border-radius: 50%;
      font-size: 20px;
    }
  }

  .view-telegram {
    color: #007AFF;
    margin: 10px 0 20px 0;
    font-size: 18px;
    padding: 10px 15px;
    width: 100%;
    background: #f7f7f7;
    border-radius: 10px;

    svg {
      path {
        fill: #007AFF;
      }
    }
  }

  .modal-header {
    margin-bottom: 20px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;

    .name {
      margin-top: 20px;
      font-size: 18px;
    }

    .actions {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .action-btn {
      width: 100%;
      margin: 5px 0;
      font-size: 18px;
      padding: 15px 20px;
    }
  }
}
</style>
