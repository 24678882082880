<template>
  <div class="feed" :class="{ 'animated': animated }" @click="$store.commit('showGuest', lastFeed.userId)">
    <span class="action">
      <img :src="getImage(lastFeed.type)" />
    </span>
    <div class="info">
      <span class="name">{{ lastFeed.title }}</span>
      <span class="description">{{ lastFeed.action }}</span>
    </div>

    <span class="ago">{{ ago }}s</span>
  </div>
</template>

<script>
import DefaultImage from '@/assets/images/feed/hug.png'
import NewMemberImage from '@/assets/images/feed/hug.png'
import MountainKingImage from '@/assets/images/feed/king.png'
import LevelUpImage from '@/assets/images/feed/levelup.png'

export default {
  name: 'Feed',
  computed: {
    lastFeed() {
      return this.$store.state.feed.lastFeed
    },
    ago() {
      const t = Math.floor(this.currentTime - this.$store.state.feed.lastFeed.ago)
      return t >= 1 ? t : 1
    },
  },
  methods: {
    getImage(type) {
      if (type === 'newMember') {
        return NewMemberImage
      } else if (type === 'mountainKing') {
        return MountainKingImage
      } else if (type === 'levelUp') {
        return LevelUpImage
      }

      return DefaultImage
    }
  },
  mounted() {
    this.interval = setInterval(() => this.currentTime = new Date().getTime() / 1000, 1000)
  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
  data() {
    return {
      animated: false,
      interval: null,
      currentTime: new Date().getTime() / 1000
    }
  },
  watch: {
    '$store.state.feed.lastFeed': {
      deep: true,
      handler() {
        this.animated = true;
        setTimeout(() => this.animated = false, 1000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(20%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.feed {
  display: flex;
  flex-direction: row;
  background: #f7f7f7;
  border-radius: 10px;
  width: 100%;
  height: 48px;
  margin-bottom: 20px;
  position: relative;
  padding: 7px 20px;
  align-items: center;
  cursor: pointer;

  &.animated {
    animation: slideIn 1s forwards;
  }

  .ago {
    font-size: 10px;
    color: grey;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .action {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: relative;
    text-align: left;

    img {
      max-width: 32px;
      position: absolute;
      border-radius: 50%;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 10px;
    justify-content: center;

    .name {
      font-size: 12px;
      margin-bottom: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 200px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      font-weight: 600;
    }

    .description {
      color: #202020;
      font-size: 10px;
    }
  }

}
</style>
