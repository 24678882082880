<template>
  <div v-if="!$store.state.siteLoading">
    <div v-if="!$store.state.introLayout" key="main">
      <div id="viewport" class="viewport">
        <ConfettiExplosion v-if="$store.state.confetti" class="confetti" />
        <transition name="fade">
          <router-view />
        </transition>
      </div>
      <Footer />
    </div>
    <div v-else key="intro">
      <Intro />
    </div>

    <Transition name="slide-right">
      <HugNoty v-if="$store.state.isHugNoty" :hugNoty="$store.state.hugNoty"
        @closeNoty="$store.commit('setHugNoty', false)" />
    </Transition>
    <Transition name="modal-fade">
      <GuestModal v-if="$store.state.guestShow" @closeModal="$store.commit('hideGuest')" />
    </Transition>
    <Transition name="modal-fade">
      <BoostModal v-if="$store.state.isShowBoostModal" @closeModal="$store.commit('showBoostModal', false)" />
    </Transition>
    <Transition name="modal-fade">
      <HowHugModal v-if="$store.state.isShowHowHugModal" @closeModal="$store.commit('showHowHugModal', false)" />
    </Transition>
    <Transition name="modal-fade">
      <ErrorModal v-if="$store.state.errorShow" @closeModal="$store.commit('setErrorShow', false)" />
    </Transition>
    <Transition name="modal-fade">
      <NotyModal v-if="$store.state.notyShow" @closeModal="$store.commit('setNotyShow', false)" />
    </Transition>
  </div>

  <Transition name="fade">
    <Loader v-if="$store.state.siteLoading" />
  </Transition>
</template>


<script>
import io from 'socket.io-client'
import Loader from './components/Loader.vue'
import Intro from './components/Intro.vue'
import HugNoty from './components/HugNoty.vue'
import Footer from './components/Footer.vue'
import ErrorModal from './components/Modals/ErrorModal.vue'
import NotyModal from './components/Modals/NotyModal.vue'
import BoostModal from './components/Modals/BoostModal.vue'

import ConfettiExplosion from "vue-confetti-explosion";
import GuestModal from './components/Modals/GuestModal.vue'
import HowHugModal from './components/Modals/HowHugModal.vue'

export default {
  name: 'App',
  components: { Loader, Footer, Intro, ErrorModal, NotyModal, BoostModal, ConfettiExplosion, GuestModal, HowHugModal, HugNoty },
  methods: {
    async init() {
      let { initData } = window.Telegram.WebApp

      this.$store.commit('getConfig')

      const urlParams = new URLSearchParams(window.location.search);
      const startAppValue = urlParams.get('tgWebAppStartParam');

      if (process.env.NODE_ENV === 'development') {
        const u = new URLSearchParams(window.location.search);
        const id = u.get('id');

        const urlParams = new URLSearchParams(`query_id=AAHBYzoVAAAAAMFjOhVWPy8p&user=%7B%22id%22%3A35621%2C%22first_name%22%3A%22testname%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22testlogin%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1&hash=12345`)
        let user = JSON.parse(urlParams.get('user'))

        user.id = id || 1234
        user.first_name = `CreatedUser#${user.id}`

        urlParams.set('user', JSON.stringify(user))

        initData = urlParams.toString()
      }

      this.$store.commit('login', { initData, startAppValue })
    },
    async initTon() {
      if (this.$store.state.tonConnectUI) {
        return
      }
      const tonConnectUI = new TON_CONNECT_UI.TonConnectUI({
        manifestUrl: process.env.NODE_ENV === 'development' ? 'https://mytwa-dev.pro/tonconnect-manifest-dev.json' : 'https://hug.cryptoblossom.xyz/tonconnect-manifest.json',
      })
      tonConnectUI.uiOptions = {
        twaReturnUrl: 'https://t.me/hugcommunity_bot/join'
      };
      this.$store.commit('setTonConnectUi', tonConnectUI)
    }
  },
  mounted() {
    const AdController = window.Adsgram.init({ blockId: "872" });
    this.$store.commit('setAdGram', AdController)

    const ioConnect = process.env.NODE_ENV === 'development' ? ':3010' : ''
    const socket = io(ioConnect)
    this.$store.commit('setSocket', socket)

    window.Telegram.WebApp.onEvent('invoiceClosed', (e) => {
      console.log(e)
      window.location.reload()
    })
    try {
      window.Telegram.WebApp.headerColor = "#fff"
      window.Telegram.WebApp.disableVerticalSwipes()
      window.Telegram.WebApp.enableClosingConfirmation()
    } catch (e) {
      console.log(e)
    }
    this.init()
    this.initTon()

    setInterval(() => this.$store.commit('updateTime'), 1000)
  }
}
</script>

<style lang="scss" scoped>
.viewport {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px 15px 80px 15px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.15s ease-out;
}

.slide-right-enter-from {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-right-leave-to {
  opacity: 0;
  transform: translateX(0%);
}

.confetti {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
</style>
