<template>
  <div class="home">
    <div class="earn flex column">
      <Earn />

      <h4 class="page-title">Earn</h4>
      <span class="description">Do simple tasks and get more $HUG</span>

      <router-link to="friends" class="daily-bonus ref-block orange-bonus">
        <h4>🤗 Invite friends</h4>
        <p>Invite friends and forever get bonuses for their activity</p>
        <Button color="black" class="claim-btn">Go to referrals</Button>
      </router-link>

      <router-link to="combo" class="daily-bonus ref-block combo-bonus">
        <h4>🍀 Daily combo</h4>
        <p>Guess the order of the emojis and win up to 100,000 $HUG!</p>
        <Button color="black" class="claim-btn">Start combo</Button>
      </router-link>

      <!-- <div class="daily-bonus" v-if="isDailyBonusClaimed === true">
        <h4>🎁 Daily bonus</h4>
        <p>You already claimed daily bonus, come back tomorrow! 😋</p>
      </div> -->

      <!-- <div class="daily-bonus" v-if="isDailyBonusClaimed === false">
        <h4>🎁 Daily bonus</h4>
        <p>Watch ad and claim free daily bonus</p>
        <Button color="blue" class="claim-btn" @on-click="claimBonus">📹 Claim</Button>
      </div> -->

      <div class="daily-bonus ton-bonus blue-bonus" v-if="$store.state.user.isTonDailyBonusClaimed === true">
        <h4><img src="@/assets/images/ton.svg" class="ton-icon" /> TON Daily</h4>
        <p>You already claimed TON daily bonus, come back tomorrow! 😋</p>
      </div>

      <div class="daily-bonus ton-bonus blue-bonus" v-if="$store.state.user.isTonDailyBonusClaimed === false">
        <h4><img src="@/assets/images/ton.svg" class="ton-icon" /> TON Daily bonus</h4>
        <p>Make any TON transaction to claim daily bonus</p>
        <div class="flex claim-row" v-if="!$store.getters.isDailyPending">
          <Button color="black" class="claim-ton-btn"
            @on-click="() => claimTonBonus(this.$store.state.config.invoiceList['ton-daily-1'].price, 'ton-daily-1:')">Claim
            1000 <img src="@/assets/images/coin.png" /></Button>
          <Button color="blue" class="claim-ton-btn"
            @on-click="() => claimTonBonus(this.$store.state.config.invoiceList['ton-daily-2'].price, 'ton-daily-2:')">Claim
            5000 <img src="@/assets/images/coin.png" /></Button>
        </div>
        <Button color="blue" class="claim-btn" v-else>Transaction processing... (~60s)</Button>
      </div>

      <div class="list-wrapper">
        <h4>Daily tasks</h4>
        <div class="list" v-if="dailyTasks.length">
          <div class="task-item" v-for="task in dailyTasks" :key="task.id" @click="showTask(task)">
            <img :src="task.iconUrl" />
            <div class="info">
              <span class="name">{{ task.title }}</span>
              <span class="description">{{ task.description }}</span>
              <span class="reward">+{{ task.reward }} $HUG</span>
            </div>
          </div>
        </div>
        <div class="list" v-else>
          <span class="no-tasks">All tasks completed 🎉</span>
        </div>
      </div>

      <div class="list-wrapper">
        <h4>Main Tasks</h4>
        <div class="list" v-if="mainTasks.length">
          <div class="task-item" v-for="task in mainTasks" :key="task.id" @click="showTask(task)">
            <img :src="task.iconUrl" />
            <div class="info">
              <span class="name">{{ task.title }}</span>
              <span class="description">{{ task.description }}</span>
              <span class="reward">+{{ task.reward }} $HUG</span>
            </div>
          </div>
        </div>
        <div class="list" v-else>
          <span class="no-tasks">All tasks completed 🎉</span>
        </div>
      </div>

      <!-- <div class="list-wrapper">
        <h4>Partner tasks</h4>
        <div class="list" v-if="partnerTasks.length">
          <div class="task-item" v-for="task in partnerTasks" :key="task.id" @click="showTask(task)">
            <img :src="task.iconUrl" />
            <div class="info">
              <span class="name">{{ task.title }}</span>
              <span class="description">{{ task.description }}</span>
            </div>
          </div>
        </div>
        <div class="list" v-else>
          <span class="no-tasks">All tasks completed 🎉</span>
        </div>
      </div> -->

      <Transition name="modal-fade">
        <TaskModal v-if="showTaskModal" :task="selectedTask" @closeModal="showTaskModal = false"
          @refreshTasks="refreshTasks" />
      </Transition>
    </div>


    <router-link to="/admin" class="admin-btn" v-if="$store.state.user.isAdmin">
      <span class="icon">⚙️</span>
      <span class="name">
        Admin
      </span>
    </router-link>
  </div>
</template>

<script>
import axios from "axios";

import { beginCell, toNano } from '@ton/ton'
import { numberWithCommas } from '../utility';

import Button from '@/components/Button.vue'
import Header from '@/components/Header.vue'
import Community from '@/components/Community.vue'
import Feed from '@/components/Feed.vue'
import RewardList from '@/components/RewardList.vue';
import TaskModal from "../components/Modals/TaskModal.vue";
import Earn from "../components/Animated/Earn.vue";

export default {
  name: 'HomeView',
  components: {
    Button,
    Header,
    RewardList,
    Community,
    Feed,
    TaskModal,
    Earn
  },
  data() {
    return {
      isDailyBonusClaimed: null,
      dailyTasks: [],
      mainTasks: [],
      partnerTasks: [],
      showTaskModal: false,
      selectedTask: null,
    }
  },
  methods: {
    numberWithCommas,
    async fetchTasks() {
      try {
        const [dailyTasksResponse, mainTasksResponse, partnerTasksResponse, dailyBonusResponse] = await Promise.all([
          axios.get('/tasks/daily-tasks'),
          axios.get('/tasks/main-tasks'),
          axios.get('/tasks/partner-tasks'),
          axios.get('/tasks/daily-bonus')
        ]);

        this.dailyTasks = dailyTasksResponse.data.tasks;
        this.mainTasks = mainTasksResponse.data.tasks;
        this.partnerTasks = partnerTasksResponse.data.tasks;
        this.isDailyBonusClaimed = dailyBonusResponse.data.isDailyBonusClaimed;
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    },
    buyVip() {
      this.$store.commit('showBoostModal', true)
    },
    refreshTasks() {
      this.fetchTasks()
    },
    async claimBonus() {
      this.$store.state.adController.show().then(async (result) => {
        try {
          await axios.post('/tasks/claim-daily-bonus');
          this.isDailyBonusClaimed = true;
          this.$store.commit('getBalance')
          this.$store.commit('explodeConfetti')
        } catch (error) {
          this.$store.commit('showError', 'Claim error')
        }
      }).catch((result) => {
        this.$store.commit('showError', 'Oops! No ads available at the moment. Try again later 🙏')
        console.log('adsgram error', result)
      })
    },
    async claimTonBonus(amount, idmsg) {
      const response = await axios.get('/tasks/is-ton-daily-claimed');
      if (response.data.isTonDailyBonusClaimed) {
        this.$store.commit('getBalance')
        this.$store.commit('explodeConfetti')
        this.$store.commit('setIsTonDailyBonusClaimed', true)
        this.$store.commit('offPending', { type: 'tonDaily' })
        return
      }

      if (!this.$store.state.tonConnectUI.connected) {
        await this.$store.state.tonConnectUI.connectWallet();
      }

      const body = beginCell()
        .storeUint(0, 32)
        .storeStringTail(idmsg + this.$store.state.user.id.toString())
        .endCell();

      const destination = this.$store.state.config.invoiceTonWallet
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: destination,
            amount: toNano(amount).toString(),
            payload: body.toBoc().toString("base64")
          }
        ]
      }

      this.$store.commit('onPending', { type: 'tonDaily' })

      const completeTransaction = await this.$store.state.tonConnectUI.sendTransaction(transaction, {
        modals: "all",
        notifications: "all",
        returnStrategy: "none"
      }).catch(e => {
        console.log(e)
      })

      if (completeTransaction && completeTransaction.boc) {
        console.log(completeTransaction)
      }
    },
    showTask(task) {
      this.selectedTask = task
      this.showTaskModal = true
    }
  },
  mounted() {
    this.fetchTasks();
  },
}
</script>

<style lang="scss" scoped>
.earn {
  text-align: center;
  justify-content: center;
}

.admin-btn {
  display: flex;
  margin: 40px 0;
  justify-content: center;
  border: 1px solid #6ae32b;
  padding: 10px 15px;
  background: #313131;
  color: white;
  border-radius: 10px;
}

.boost-btn {
  margin: 10px 0;
  flex-direction: row;

  img {
    margin-right: 5px;
  }
}

.page-title {
  font-size: 24px;
  margin: 10px 0;

  @media (max-width: 386px) {
    font-size: 26px;
  }
}

.description {
  font-size: 14px;
  color: #656565;
  margin-bottom: 5px;
}

.reward {
  color: #007AFF;
  font-weight: 700;
  font-size: 14px;
}

.daily-bonus {
  display: flex;
  margin: 10px 0;
  padding: 10px 20px;
  background: #f3f3f3;
  border-radius: 10px;
  flex-direction: column;

  h4 {
    display: flex;
    align-items: center;
    text-align: left;

    img {
      margin-right: 5px;
    }
  }

  p {
    text-align: left;
    margin: 10px 0;
    color: #4F4F4F;
    font-size: 14px;

    @media (max-width: 390px) {
      font-size: 12px;
    }
  }

  &.orange-bonus {
    background: #fff1d7;
  }

  &.combo-bonus {
    background: #e0ffd7;
  }

  &.blue-bonus {
    background: #e1efff;
  }

  &.ton-bonus {
    display: flex;
    width: 100%;
    text-align: left;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

    .ton-icon {
      width: 20px;
    }

    .claim-btn {
      margin-top: 10px;
      width: 100%;
    }
  }

  .vip-bonus {
    display: flex;
    margin: 10px 0;
    align-items: center;
    background: #f6f6f6;
    padding: 7px 10px;
    border-radius: 10px;
    width: 100%;
    justify-content: space-between;
    border: 2px solid #ffd474;

    @media (max-width: 410px) {
      font-size: 14px;
    }

    @media (max-width: 382px) {
      font-size: 12px;
    }

    .info {
      display: flex;

      span {
        text-align: left;
      }
    }

    img {
      margin-right: 5px;
    }

    .yellow {
      color: #FFC134;
    }
  }

  .claim-btn {
    margin-top: 5px;
  }
}

.claim-row {
  width: 100%;
  gap: 0 10px;
  margin-top: 5px;

  .claim-ton-btn {
    flex-direction: row;
    width: 50%;

    img {
      width: 18px;
      margin-left: 5px;
    }

    @media (max-width: 408px) {
      font-size: 12px;

      img {
        width: 16px;
        margin-left: 3px;
      }
    }
  }
}

.list-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 20px 0;

  h4 {
    padding-left: 10px;
    text-align: left;
    margin-bottom: 5px;
  }

  .list {
    display: flex;
    width: 100%;
    background: #f5f5f5;
    padding: 10px;
    flex-direction: column;
    border-radius: 10px;
    gap: 10px 0;

    .task-item {
      display: flex;
      width: 100%;
      background: #fff;
      padding: 10px;
      border-radius: 10px;
      align-items: center;

      img {
        width: 48px;
        height: 48px;
        border-radius: 10px;
        border: 1px solid #c8c8c8;
      }

      .info {
        display: flex;
        margin-left: 10px;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        text-align: left;
        gap: 5px 0;

        .name {
          font-weight: 600;
        }

        .description {
          color: #4F4F4F;
        }
      }
    }
  }
}
</style>