<template>
  <div class="bonus centered">
    <span class="title">🎁 Gift to you</span>
    <span class="description">We apologize for the long time away and want to give you bonus $HUGs</span>
    <Button color="orange" class="join-btn" @on-click="() => claim()">Claim</Button>
  </div>
</template>

<script>
import Button from './Button.vue'
import axios from 'axios'

export default {
  name: 'Bonus',
  components: { Button },
  methods: {
    claim() {
      axios.post('/user/claim-bonus').then(response => {
        this.$store.commit('getBalance')
        this.$store.commit('explodeConfetti')
        this.$store.commit('claimGift')
        this.$store.commit('showNoty', `You got ${response.data.reward} bonus $HUG!`)
      }).catch(e => {
        this.$store.commit('showError', `You've already claimed gift`)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.bonus {
  display: flex;
  flex-direction: column;
  gap: 15px 0;
  background: #e2f0ff;
  padding: 15px 20px;
  border-radius: 10px;
  width: 100%;
  margin: 20px 0;

  .title {
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }

  .description {
    color: #4F4F4F;
    font-size: 14px;

    .blue {
      color: #007AFF;
    }

    @media (max-width: 386px) {
      font-size: 14px;
    }
  }

  .join-btn {
    width: 100%;
  }
}
</style>
