<template>
  <div class="skeleton" :style="`height: ${height || 100}px`"></div>
</template>

<script>
export default {
  name: 'Skeleton',
  props: ['height']
}
</script>

<style lang="scss" scoped>
.skeleton {
  display: flex;
  width: 100%;
  background: rgb(236, 236, 236);
  border-radius: 10px;
  margin: 20px 0;
  animation: SkeletonAnimation 2s linear infinite;
}

@keyframes SkeletonAnimation {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0
  }
}
</style>