<template>
  <div class="race centered column">
    <Trophy class="trophy" />

    <span class="description">
      We are raffling off <span class="blue">265 TON</span> to those <br />who will earn the most $HUG in a monthly
      period.
      <br />
      <i>1 Race Point = 1 $HUG</i>
    </span>

    <div class="vip-bonus" @click="buyVip">
      <div class="info">
        <img src="@/assets/images/yellow-star.svg" />
        <span>
          <span class="yellow">VIP users</span><br />receive <b>x2</b> Race Points
        </span>
      </div>
      <Button color="orange" class="get-vip-btn" v-if="!$store.state.user.isVip">Get VIP</Button>
    </div>

    <Button color="blue" class="boost-btn" @on-click="() => $store.commit('showBoostModal', true)"><img
        src="@/assets/images/yellow-star.svg" />
      Boost $HUG</Button>

    <template v-if="list.length">
      <span class="separator" v-if="$store.state.config.raceEnabled">
        <span class="left">Race will end on <b>31 August 18:00 (UTC)</b></span>
        <span class="right">Top 100</span>
      </span>
      <span class="separator" v-else>
        <span class="left"><b>Race is over!</b></span>
        <span class="right">Winners</span>
      </span>

      <div class="my-place">
        <div class="place flex row">
          <div class="user-info centered">
            <Avatar :avatarColor="$store.state.user.avatarColor" :avatarIcon="$store.state.user.avatarIcon"
              :telegramLogin="$store.state.user.telegramLogin" :level="$store.state.user.level" />
            <div class="info">
              <Name username="You" :isVip="$store.state.user.isVip" />
              <span class="balance">
                {{ numberWithCommas($store.state.user.raceBalance) }} RP
                <div class="reward" v-if="(myPlace - 1) < $store.state.config.racePrizes.length && myPlace !== null">
                  <img src="@/assets/images/ton.svg" />
                  <span>{{ $store.state.config.racePrizes[myPlace - 1] }}</span>
                </div>
              </span>
            </div>
          </div>
          <span class="place-num flex centered" v-if="myPlace !== null">{{ getPlaceIcon(myPlace) }}</span>
          <span class="place-num flex centered" v-else>-</span>
        </div>
      </div>
    </template>
    <template v-else>
      <Skeleton height="600" />
    </template>

    <div class="places">
      <div class="place flex row pointer" v-for="(member, i) in list" :key="i"
        @click="$store.commit('showGuest', member.userId)">
        <div class="user-info centered">
          <Avatar :avatarColor="member.avatarColor" :avatarIcon="member.avatarIcon"
            :telegramLogin="member.telegramLogin" :level="member.level" />
          <div class="info">
            <Name :username="member.username" :isVip="member.isVip" />
            <span class="balance">
              {{ numberWithCommas(+member.balance) }} RP
              <div class="reward" v-if="i < $store.state.config.racePrizes.length">
                <img src="@/assets/images/ton.svg" />
                <span>{{ $store.state.config.racePrizes[i] }}</span>
              </div>
            </span>
          </div>
        </div>
        <span class="place-num flex centered" :class="{ 'big': (i + 1) <= 3 }">{{ getPlaceIcon(i + 1) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { numberWithCommas } from '@/utility';
import Trophy from '@/components/Animated/Trophy.vue';
import Button from '@/components/Button.vue';
import Skeleton from '@/components/Skeleton.vue';
import Name from '@/components/Name.vue';
import Avatar from '@/components/Avatar.vue';

export default {
  name: 'LeaderboardRace',
  components: {
    Skeleton,
    Button,
    Trophy,
    Avatar,
    Name
  },
  methods: {
    numberWithCommas,
    getPlaceIcon(place) {
      if (place === 1) {
        return `🥇`
      } else if (place === 2) {
        return `🥈`
      } else if (place === 3) {
        return `🥉`
      } else {
        return `#${place}`
      }
    },
    async fetchRace() {
      const { data } = await axios.get('/race/list')
      this.total = data.total
      this.list = data.list
      this.myPlace = data.myPlace
    },
    buyVip() {
      this.$store.commit('showBoostModal', true)
    }
  },
  data() {
    return {
      list: [],
      total: 0,
      myPlace: 0,
    }
  },
  mounted() {
    this.fetchRace()
  }
}
</script>

<style lang="scss" scoped>
.boost-btn {
  margin-top: 15px;
  flex-direction: row;
  width: 100%;

  img {
    margin-right: 5px;
  }
}

.race {
  display: flex;

  .get-vip-btn {
    font-size: 12px;
    padding: 10px 15px;
    width: 100px;
  }

  .page-title {
    font-size: 32px;
    font-weight: 800;

    @media (max-width: 386px) {
      font-size: 26px;
    }
  }

  .trophy {
    margin: 20px 0;
  }

  .description {
    color: #4F4F4F;
    font-size: 14px;

    .blue {
      color: #007AFF;
      font-weight: 700;
    }

    i {
      display: block;
      font-size: 12px;
      margin-top: 10px;
    }
  }

  .vip-bonus {
    display: flex;
    margin: 10px 0;
    align-items: center;
    background: #f6f6f6;
    padding: 7px 10px;
    border-radius: 10px;
    width: 100%;
    justify-content: space-between;
    border: 1px solid #ffd474;

    @media (max-width: 410px) {
      font-size: 14px;
    }

    @media (max-width: 382px) {
      font-size: 12px;
    }

    .info {
      display: flex;

      span {
        text-align: left;
      }
    }

    img {
      margin-right: 5px;
    }

    .yellow {
      color: #FFC134;
    }
  }

  .separator {
    display: flex;
    justify-content: space-between;
    margin: 30px 0 10px 0;
    font-size: 14px;
    padding: 0 10px;
    width: 100%;
    align-items: center;

    .left {
      font-size: 12px;
    }

    .right {
      color: #4F4F4F;
    }

    b {
      font-weight: 700;
    }
  }

  .my-place {
    display: flex;
    gap: 20px 0;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    background: #F6F6F6;
    border-radius: 10px;
  }

  .places {
    display: flex;
    gap: 20px 0;
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
    margin-top: 20px;
  }

  .place {
    justify-content: space-between;

    .user-info {
      display: flex;

      .avatar {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        position: relative;

        img {
          max-width: 46px;
          position: absolute;
          border-radius: 50%;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 10px;

        .name {
          font-size: 16px;
          margin-bottom: 5px;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 200px;
          white-space: nowrap;
          display: flex;
          align-items: center;
          font-weight: 500;

          &.vip-name {
            color: #fbc751;
            font-weight: 600;
          }

          img {
            width: 21px;
          }
        }

        .vip {
          margin-right: 3px;
        }

        .balance {
          display: flex;
          font-weight: 600;
          color: #636363;

          .reward {
            display: flex;
            align-items: center;

            img {
              width: 18px;
              margin: 0 5px;
            }

            span {
              color: #007AFF;
              font-weight: 600;
            }
          }
        }
      }
    }

    .place-num {
      &.big {
        font-size: 24px;
      }
    }
  }
}
</style>