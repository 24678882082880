<template>
  <div class="ton-wallet centered">
    <Button class="connect-btn" color="blue" @on-click="connect"
      v-if="$store.state.tonConnectUI && !$store.state.user.tonWallet">Connect wallet</Button>
    <Button class="connect-btn" color="blue" @on-click="() => showWalletModal = true"
      v-if="$store.state.tonConnectUI && $store.state.user.tonWallet">
      {{ $store.state.user.tonWallet.slice(0, 3) + '...' + $store.state.user.tonWallet.slice(-3) }}
    </Button>

    <Transition name="modal-fade">
      <WalletModal v-if="showWalletModal" @closeModal="showWalletModal = false" />
    </Transition>
  </div>
</template>

<script>
import axios from 'axios'
import Button from './Button.vue'
import { toUserFriendlyAddress } from '@tonconnect/ui';
import WalletModal from './Modals/WalletModal.vue'

export default {
  name: 'TonWallet',
  components: { Button, WalletModal },
  data() {
    return {
      showWalletModal: false,
    }
  },
  methods: {
    async connect() {
      try {
        this.$store.state.tonConnectUI.disconnect()
      } catch (e) { console.log('wallet error', e) }
      const connectedWallet = await this.$store.state.tonConnectUI.connectWallet();
      if (connectedWallet) {
        const wallet = toUserFriendlyAddress(this.$store.state.tonConnectUI.wallet.account.address)
        await axios.post('/user/ton-wallet', {
          wallet
        }).then(response => {
          this.$store.commit('setWallet', wallet)

          if (response.data.nomisReward) {
            console.log(response.data.nomisReward)
          }
        }).catch(e => {
          this.$store.commit('showError', 'Wallet error')
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ton-wallet {
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    align-items: center;

    .ton,
    .nomis {
      width: 24px;
      margin-right: 8px;
      border-radius: 50%;
      border: 1px solid #a7d1ff;
    }

    .nomis {
      margin-left: 8px;
    }
  }

  .description {
    color: #4F4F4F;
    font-size: 14px;

    .blue {
      color: #007AFF;
    }

    @media (max-width: 386px) {
      font-size: 14px;
    }
  }

  .connect-btn {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    padding: 5px 10px;

    .ton {
      width: 24px;
      margin-right: 8px;
      border-radius: 50%;
      border: 1px solid #a7d1ff;
    }
  }
}
</style>
