<template>
  <div class="leaderboard centered">

    <h4 class="page-title">Leaderboard</h4>

    <div class="my-place">
      <div class="place flex row">
        <div class="user-info centered">
          <Avatar :avatarColor="$store.state.user.avatarColor" :avatarIcon="$store.state.user.avatarIcon"
            :telegramLogin="$store.state.user.telegramLogin" :level="$store.state.user.level" />
          <div class="info">
            <Name username="You" :isVip="$store.state.user.isVip" />
            <span class="balance">{{ numberWithCommas($store.state.user.balance) }} $HUG</span>
          </div>
        </div>
        <span class="place-num flex centered" v-if="myPlace !== null">{{ getPlaceIcon(myPlace) }}</span>
        <span class="place-num flex centered" v-else>-</span>
      </div>
    </div>

    <Button color="blue" class="boost-btn" @on-click="boost"><img src="@/assets/images/yellow-star.svg" />
      Boost $HUG</Button>

    <template v-if="list.length">
      <span class="separator">
        <span class="left"><b>{{ numberWithCommas(total) }}</b> people in our community</span>
        <span class="right">Top 100</span>
      </span>

      <div class="places">
        <div class="place flex row pointer" v-for="(member, i) in list" :key="i"
          @click="$store.commit('showGuest', member.userId)">
          <div class="user-info centered">
            <Avatar :avatarColor="member.avatarColor" :avatarIcon="member.avatarIcon"
              :telegramLogin="member.telegramLogin" :level="member.level" />
            <div class="info">
              <Name :username="member.username" :isVip="member.isVip" />
              <span class="balance">{{ numberWithCommas(+member.balance) }} $HUG</span>
            </div>
          </div>
          <span class="place-num flex centered" :class="{ 'big': (i + 1) <= 3 }">{{ getPlaceIcon(i + 1) }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <Skeleton height="600" />
    </template>
  </div>
</template>

<script>
import axios from "axios";
import { numberWithCommas } from '@/utility';
import Skeleton from "@/components/Skeleton.vue";
import Button from "@/components/Button.vue";
import BoostModal from "@/components/Modals/BoostModal.vue";
import Avatar from "../Avatar.vue";
import Name from "../Name.vue";

export default {
  name: 'LeaderboardMain',
  components: {
    Skeleton,
    Button,
    BoostModal,
    Avatar,
    Name
  },
  methods: {
    numberWithCommas,
    getPlaceIcon(place) {
      if (place === 1) {
        return `🥇`
      } else if (place === 2) {
        return `🥈`
      } else if (place === 3) {
        return `🥉`
      } else {
        return `#${place}`
      }
    },
    async fetchLeaderboard() {
      const { data } = await axios.get('/leaderboard/list')
      this.total = data.total
      this.list = data.list
      this.myPlace = data.myPlace
    },
    boost() {
      this.$store.commit('showBoostModal', true)
    }
  },
  data() {
    return {
      list: [],
      total: 0,
      myPlace: 0,
    }
  },
  mounted() {
    this.fetchLeaderboard()
  }
}
</script>

<style lang="scss" scoped>
.boost-btn {
  margin-top: 15px;
  flex-direction: row;

  img {
    margin-right: 5px;
  }
}

.leaderboard {
  .page-title {
    font-size: 32px;
    font-weight: 800;
    margin: 10px 0 20px 0;

    @media (max-width: 386px) {
      font-size: 26px;
    }
  }

  .separator {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
    font-size: 14px;
    padding: 0 10px;

    .right {
      color: #4F4F4F;
    }

    b {
      font-weight: 700;
    }
  }

  .my-place {
    display: flex;
    gap: 20px 0;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    margin-top: 30px;
    background: #F6F6F6;
    border-radius: 10px;
  }

  .places {
    display: flex;
    gap: 20px 0;
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
    margin-top: 30px;
  }

  .place {
    justify-content: space-between;

    .user-info {
      display: flex;

      .avatar {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        position: relative;

        img {
          max-width: 46px;
          position: absolute;
          border-radius: 50%;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 10px;

        .name {
          font-size: 16px;
          margin-bottom: 5px;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 200px;
          white-space: nowrap;
          display: flex;
          align-items: center;
          font-weight: 500;

          &.vip-name {
            color: #fbc751;
            font-weight: 600;
          }

          img {
            width: 21px;
          }
        }

        .vip {
          margin-right: 3px;
        }

        .balance {
          font-weight: 600;
          color: #636363;
        }
      }
    }

    .place-num {
      &.big {
        font-size: 24px;
      }
    }
  }
}
</style>