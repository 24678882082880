<template>
  <Modal>
    <TelegramStar class="star" />

    <div class="modal-header">
      <h4 class="title">Oops 😢</h4>
      <span class="description">{{ $store.state.errorMessage }}</span>
      <div class="close centered" @click="$emit('closeModal')">X</div>
    </div>

    <Button class="close-btn" @on-click="() => $emit('closeModal')">Close</Button>
  </Modal>
</template>

<script>
import Modal from './Modal.vue'
import Button from '../Button.vue'

export default {
  name: 'ErrorModal',
  components: { Modal, Button },
  methods: {},
  data() {
    return {
      selectedItem: null,
    }
  },
}
</script>

<style scoped lang="scss"></style>
