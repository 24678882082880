<template>
  <div class="hug-noty" @click="closeNoty">
    <img src="@/assets/images/icons/hug-footer.svg" /><b>{{ hugNoty.username }}</b> hugged you!
    <span class="click">></span>
  </div>
</template>

<script>

export default {
  name: 'HugNoty',
  props: ['hugNoty'],
  methods: {
    closeNoty() {
      this.$emit('closeNoty')
      this.$store.commit('showGuest', this.hugNoty.huggedFrom)
    }
  }
}
</script>

<style scoped lang="scss">
.hug-noty {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 30%;
  background: #ffffff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 5px 10px;
  border: 2px solid #ececec;
  border-left: none;
  z-index: 103;
  cursor: pointer;
  padding-right: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  b {
    margin-right: 3px;
  }

  img {
    width: 20px;
    margin-right: 5px;
  }

  .click {
    position: absolute;
    right: 0;
    color: black;
    font-weight: 300;
    width: 20px;
    height: 100%;
    background: #d7d7d7;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 3px;
  }
}
</style>
