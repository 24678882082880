<template>
  <Modal>
    <img :src="task.iconUrl" class="task-img" />

    <div class="modal-header">
      <h4 class="title">{{ task.title }}</h4>
      <span class="description">{{ task.description }}</span>
      <div class="close centered" @click="$emit('closeModal')">X</div>
    </div>

    <h4 class="reward">+{{ task.reward }} $HUG</h4>

    <template v-if="task.taskType === 'story'">
      <Button color="blue" class="task-btn" @on-click="start" v-if="!twoStep">Share story</Button>
      <Button color="black" class="task-btn" @on-click="check" v-else>Check task</Button>
    </template>
    <template v-else-if="task.taskType === 'partner'">
      <Button color="blue" class="task-btn" @on-click="start" v-if="!twoStep">Start task</Button>
      <Button color="black" class="task-btn" @on-click="check" v-else>Check task</Button>
    </template>
    <template v-else>
      <Button color="blue" class="task-btn" @on-click="start"
        v-if="!['daily_bonus', 'ton_daily_reward'].includes(task.taskType)">Start task</Button>
      <Button color="black" class="task-btn" @on-click="check">Check task</Button>
    </template>
  </Modal>
</template>

<script>
import axios from "axios";

import { numberWithCommas } from '../../utility';
import Modal from './Modal.vue'
import Button from '../Button.vue'

export default {
  name: 'TaskModal',
  components: { Modal, Button },
  props: ['task'],
  data() {
    return {
      twoStep: false
    }
  },
  methods: {
    numberWithCommas,
    async start() {
      if (this.task.payload && this.task.payload.link) {
        window.Telegram.WebApp.openTelegramLink(this.task.payload.link)
        setTimeout(() => this.twoStep = true, 2000)
        return
      } else if (this.task.taskType === 'daily_bonus') {
        this.$emit('closeModal')
        const viewport = document.getElementById("viewport");
        if (viewport) {
          viewport.scrollTo(0, 0);
        }
      } else if (this.task.taskType === 'ton_wallet') {
        this.$router.push({ path: '/' })
      } else if (this.task.taskType === 'invite') {
        this.$router.push({ path: '/friends' })
      } else if (this.task.taskType === 'mountain_king') {
        this.$router.push({ path: '/king' })
      } else if (this.task.taskType === 'story') {
        try {
          const story = this.$store.state.config.baseDomain + `/story.mp4`
          const refLink = this.$store.state.config.telegramBotLinkJoin + '?startapp=' + this.$store.state.user.referralCode
          const text = `${refLink} #HUG #HUGCommunity`
          window.Telegram.WebApp.shareToStory(story, {
            text
          })
          setTimeout(() => this.twoStep = true, 2000)
        } catch (e) {
          console.log(e)
          this.$store.commit('showError', 'Update Telegram to share story')
        }
      } else {
        this.$emit('closeModal')
      }
    },
    async check() {
      try {
        await axios.post('/tasks/complete-task', { taskId: this.task.id });
        this.$store.commit('getBalance')
        this.$emit('closeModal')
        this.$emit('refreshTasks')
        this.$store.commit('explodeConfetti')
      } catch (error) {
        this.$store.commit('showError', error.response.data.message)
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.task-img {
  border-radius: 10px;
  margin-bottom: 10px;
}

.reward {
  margin: 20px 0;
  font-size: 20px;
}

.task-btn {
  margin-top: 10px;
  flex-direction: row;
  height: 44px;
  font-size: 16px;

  @media screen and (max-height: 645px) {
    margin-top: 5px;
  }
}
</style>
