import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios';
import Vue3Lottie from 'vue3-lottie'

import './assets/styles/base.scss'

import { Buffer } from 'buffer';

// @ts-ignore
window.Buffer = Buffer;

const app = createApp(App)
app.use(store)

app.use(Vue3Lottie);

axios.defaults.withCredentials = true
axios.interceptors.request.use(
  function (config) {
    if (store.state.authToken) {
      config.headers["Auth-Token"] = store.state.authToken
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : '/api'
// axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'https://mytwa-dev.pro/api' : '/api'
app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)

app.use(router).mount('#app')