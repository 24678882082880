<template>
  <div class="btn centered" :class="[color || 'black', freeze && 'freeze', disabled ? 'disabled' : '']"
    @click="onClickBtn">
    <slot></slot>
  </div>
</template>

<script>
import { vibrate } from '../utility'

export default {
  name: 'Button',
  props: ['color', 'disabled'],
  data() {
    return {
      freeze: false
    }
  },
  methods: {
    onClickBtn() {
      if (this.freeze) {
        return
      }

      if (this.disabled) {
        return
      }

      this.freeze = true
      setTimeout(() => this.freeze = false, 200)

      vibrate()
      this.$emit('on-click')
    },
  }
}
</script>

<style scoped lang="scss">
.btn {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 25px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  opacity: 1;
  transition: all 0.15s ease-in-out;

  &.black {
    background: #303030;
  }

  &.blue {
    background: #007AFF;
  }

  &.orange {
    background: #FFB92E;
  }

  &.freeze,
  &.disabled {
    opacity: 0.7;
    cursor: default;
  }
}
</style>
