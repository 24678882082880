<template>
  <div class="intro" @click="nextStep">
    <div class="steps">
      <div class="step active" v-for="a in (step + 1)" :key="a"></div>
      <div class="step" v-for="b in (4 - (step + 1))" :key="b"></div>
    </div>
    <div class="intro-item centered" v-if="step === 0">
      <h4>Welcome to the<br /><span class="blue">HUG game!</span></h4>
      <!-- <p>Lorem ipsum dolor sit amet consectetur.</p> -->
      <img src="@/assets/images/intro/hug.png" />
    </div>
    <div class="intro-item centered" v-if="step === 1">
      <h4 class="small">You can interact with other players
        <span class="blue">by giving them hugs</span>
      </h4>
      <p class="small">You will get $HUG tokens for each activity</p>
      <img src="@/assets/images/intro/hug2.png" />
    </div>
    <div class="intro-item centered" v-if="step === 2">
      <h4 class="small">HUG progress and reward
        <span class="blue">depends on the level</span>
      </h4>
      <p class="small">Level up and get to the top</p>
      <img src="@/assets/images/intro/level.png" class="full" />
    </div>
    <div class="intro-item centered" v-if="step === 3">
      <h4 class="small">We hold tournaments among
        <span class="blue">the most active players</span>
      </h4>
      <p>There is a <span class="blue">265 TON</span> tournament going on right now</p>
      <Trophy style="margin-top: 30px;" />

      <Button color="blue" class="start-btn">Start play</Button>
    </div>
    <svg class="click-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
      width="96" height="96" viewBox="0 0 256 256" xml:space="preserve">
      <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
        <path
          d="M 69.416 43.298 H 68.97 c -0.983 0 -1.917 0.216 -2.756 0.603 c -0.644 -2.975 -3.295 -5.21 -6.459 -5.21 h -0.447 c -1.128 0 -2.19 0.284 -3.12 0.784 c -1 -2.379 -3.355 -4.054 -6.094 -4.054 h -0.447 c -0.925 0 -1.807 0.191 -2.606 0.536 v -9.458 c 0 -3.644 -2.964 -6.607 -6.608 -6.607 h -0.447 c -3.643 0 -6.607 2.964 -6.607 6.607 v 24.261 l -3.005 2.281 c -2.394 1.817 -3.911 4.461 -4.273 7.444 c -0.362 2.984 0.479 5.914 2.37 8.251 l 9.378 11.594 v 4.608 c 0 2.791 2.271 5.063 5.062 5.063 h 23.411 c 2.791 0 5.063 -2.271 5.063 -5.063 l 0.001 -4.375 c 2.996 -3.766 4.639 -8.438 4.639 -13.242 V 49.905 C 76.023 46.262 73.06 43.298 69.416 43.298 z M 72.023 67.32 c 0 4.102 -1.478 8.088 -4.159 11.224 c -0.311 0.362 -0.48 0.823 -0.48 1.3 v 5.094 c 0 0.586 -0.477 1.063 -1.063 1.063 H 42.911 c -0.585 0 -1.062 -0.477 -1.062 -1.063 v -5.316 c 0 -0.458 -0.157 -0.902 -0.445 -1.258 L 31.581 66.22 c -1.204 -1.488 -1.74 -3.354 -1.509 -5.254 c 0.23 -1.899 1.197 -3.583 2.721 -4.74 l 0.586 -0.444 v 5.495 c 0 1.104 0.896 2 2 2 s 2 -0.896 2 -2 v -9.506 c 0 -0.014 0 -0.026 0 -0.04 V 26.498 c 0 -1.438 1.169 -2.607 2.607 -2.607 h 0.447 c 1.438 0 2.607 1.17 2.607 2.607 v 15.53 c 0 0 0 0 0 0.001 s 0 0 0 0.001 l 0.01 12.917 c 0.001 1.104 0.896 1.998 2 1.998 c 0 0 0.001 0 0.001 0 c 1.104 -0.001 1.999 -0.896 1.998 -2.002 L 47.04 42.028 c 0 -1.438 1.169 -2.607 2.606 -2.607 h 0.447 c 1.438 0 2.607 1.17 2.607 2.607 v 3.27 c 0 0 0 0.001 0 0.001 c 0 0 0 0.001 0 0.001 l 0.009 9.647 c 0.001 1.104 0.896 1.998 2 1.998 c 0.001 0 0.001 0 0.002 0 c 1.104 -0.001 1.999 -0.897 1.998 -2.002 l -0.009 -9.646 c 0 -1.438 1.169 -2.607 2.606 -2.607 h 0.447 c 1.438 0 2.607 1.17 2.607 2.607 v 4.088 c -0.006 0.066 -0.02 0.13 -0.02 0.197 l 0.01 5.366 c 0.002 1.104 0.897 1.996 2 1.996 c 0.001 0 0.002 0 0.004 0 c 1.104 -0.002 1.998 -0.899 1.996 -2.004 l -0.009 -4.852 c 0.006 -0.062 0.019 -0.121 0.019 -0.184 c 0 -1.438 1.17 -2.607 2.607 -2.607 h 0.446 c 1.438 0 2.607 1.17 2.607 2.607 V 67.32 z"
          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;"
          transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
        <path
          d="M 63.994 25.511 H 51.79 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 12.204 c 1.104 0 2 0.896 2 2 S 65.099 25.511 63.994 25.511 z"
          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;"
          transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
        <path
          d="M 39.985 16.204 c -1.104 0 -2 -0.896 -2 -2 V 2 c 0 -1.104 0.896 -2 2 -2 s 2 0.896 2 2 v 12.204 C 41.985 15.309 41.09 16.204 39.985 16.204 z"
          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;"
          transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
        <path
          d="M 48.558 18.441 c -0.512 0 -1.023 -0.195 -1.414 -0.586 c -0.781 -0.781 -0.781 -2.047 0 -2.828 l 8.63 -8.629 c 0.781 -0.781 2.047 -0.781 2.828 0 c 0.781 0.781 0.781 2.047 0 2.828 l -8.63 8.629 C 49.581 18.246 49.069 18.441 48.558 18.441 z"
          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;"
          transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
        <path
          d="M 28.181 25.511 H 15.977 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 12.204 c 1.104 0 2 0.896 2 2 S 29.285 25.511 28.181 25.511 z"
          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;"
          transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
        <path
          d="M 31.413 18.441 c -0.512 0 -1.024 -0.195 -1.414 -0.586 L 21.37 9.226 c -0.781 -0.781 -0.781 -2.047 0 -2.828 c 0.78 -0.781 2.048 -0.781 2.828 0 l 8.629 8.629 c 0.781 0.781 0.781 2.047 0 2.828 C 32.437 18.246 31.925 18.441 31.413 18.441 z"
          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;"
          transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
      </g>
    </svg>
  </div>
</template>

<script>
import Trophy from './Animated/Trophy.vue';
import Button from './Button.vue'
import axios from 'axios';

export default {
  name: 'Intro',
  components: { Button, Trophy },
  data() {
    return {
      step: 0,
    }
  },
  methods: {
    exit() {
      this.$store.commit('exitIntro')
    },
    nextStep() {
      if (this.step >= 3) {
        this.exit()
        return;
      }
      this.step++
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
@keyframes slide {
  from {
    bottom: 40px;
  }

  to {
    bottom: 15px;
  }
}

.intro {
  display: flex;
  background: linear-gradient(to bottom, #fff 38%, #2865A8);
  height: 100vh;
  flex-direction: column;
  position: relative;

  span {
    &.blue {
      color: #2865A8;
    }
  }

  .start-btn {
    font-size: 20px;
    padding: 20px 15px;
    margin-top: 40px;
  }

  .click-svg {
    position: absolute;
    bottom: 20px;
    left: calc(50% - 48px);
    animation: 2s infinite alternate slide;

    g {
      path {
        fill: #ffffff46;
      }
    }
  }

  .steps {
    display: flex;
    width: 100%;
    gap: 0 5px;
    margin-top: 10px;
    padding: 15px;

    .step {
      width: 25%;
      height: 3px;
      background: rgb(130, 130, 130);
      border-radius: 10px;

      &.active {
        background: black;
      }
    }
  }

  .intro-item {
    padding: 15px;
    margin-top: 30px;

    h4 {
      font-size: 32px;
      margin-bottom: 20px;

      &.small {
        font-size: 20px;
      }
    }

    p {
      font-size: 24px;

      &.small {
        font-size: 18px;
      }
    }

    img {
      max-width: 80%;
      margin-top: 20px;

      &.full {
        max-width: 100%;
      }
    }
  }
}
</style>
