import { createStore } from 'vuex'
import axios from "axios";

import { nextTick } from 'vue';
import router from '../router';
import feed from './feed'
import { vibrate } from '../utility';

export default createStore({
  state() {
    return {
      time: 0,

      socket: null,
      introLayout: false,
      introStep: 0,
      authToken: '',
      siteLoading: true,
      user: {},
      config: {},
      rewards: [],

      tonConnectUI: null,

      errorShow: false,
      errorMessage: '',

      notyShow: false,
      notyMessage: '',

      guestShow: false,
      guest: null,

      adController: null,

      confetti: false,

      isShowBoostModal: false,
      isShowHowHugModal: false,

      hugNoty: null,
      hugTimer: null,
      isHugNoty: false,

      pendings: {
        tonDaily: {
          enabled: false,
          timeout: null,
          time: 60,
        },
        mountainKing: {
          enabled: false,
          timeout: null,
          time: 20,
        },
        boost: {
          enabled: false,
          timeout: null,
          time: 20,
        }
      }
    }
  },
  getters: {
    isDailyPending(state) {
      return state.pendings['tonDaily'].enabled
    },
    isKingPending(state) {
      return state.pendings['mountainKing'].enabled
    },
    isBoostPending(state) {
      return state.pendings['boost'].enabled
    },
    tonBoostList(state) {
      const keys = ['hug-1', 'hug-2', 'hug-3', 'hug-4', 'hug-5', 'hug-6']
      return keys.map(k => {
        return { ...state.config.invoiceList[k], id: k }
      })
    },
    currentLevel(state) {
      return state.config.levels.find(l => l.level === state.user.level)
    },
    nextLevel(state) {
      return state.config.levels.find(l => l.level === state.user.level + 1)
    },
    isHugAvailable(state) {
      if (!state.user.lastHugAt) {
        return true
      }

      return state.time - new Date(state.user.lastHugAt).getTime() / 1000 >= state.config.hugCooldown
    },
    hugCooldown(state) {
      if (!state.user.lastHugAt) {
        return 0
      }
      const sec = Math.round(state.config.hugCooldown - (state.time - new Date(state.user.lastHugAt).getTime() / 1000))

      var sec_num = parseInt(sec, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours < 10) { hours = "0" + hours; }
      if (minutes < 10) { minutes = "0" + minutes; }
      if (seconds < 10) { seconds = "0" + seconds; }

      return hours + ':' + minutes + ':' + seconds;
    }
  },
  mutations: {
    showBoostModal(state, show) {
      state.isShowBoostModal = show
    },
    showHowHugModal(state, show) {
      state.isShowHowHugModal = show
    },
    setHugNoty(state, hugNoty) {
      if (state.hugTimer) {
        clearTimeout(state.hugTimer)
      }
      if (hugNoty) {
        state.hugNoty = hugNoty
        state.isHugNoty = true
        state.hugTimer = setTimeout(() => state.isHugNoty = false, 20000)
      } else {
        state.isHugNoty = false
      }
    },
    updateTime(state) {
      state.time = Math.round(new Date().getTime() / 1000)
    },
    setLastHugAt(state, date) {
      state.user.lastHugAt = date
    },
    showGuest(state, userId) {
      if (userId === state.user.id) {
        return
      }
      state.guest = userId
      state.guestShow = true
    },
    hideGuest(state) {
      state.guestShow = false
    },
    setSocket(state, socket) {
      state.socket = socket
    },
    levelup(state) {
      state.user.level += 1
    },
    claimGift(state) {
      state.user.isGiftClaimed = true
    },
    async explodeConfetti(state) {
      state.confetti = false;
      await nextTick();
      state.confetti = true;
    },
    onPending(state, data) {
      if (state.pendings[data.type].timeout) {
        clearTimeout(state.pendings[data.type].timeout)
      }

      state.pendings[data.type].enabled = true
      state.pendings[data.type].timeout = setTimeout(() => {
        state.pendings[data.type].enabled = false
      }, state.pendings[data.type].time * 1000)
    },
    offPending(state, data) {
      if (state.pendings[data.type].timeout) {
        clearTimeout(state.pendings[data.type].timeout)
      }
      state.pendings[data.type].enabled = false
    },
    initSocketListeners(state) {
      state.socket.on('boost-paid', (data) => {
        if (+data.userId === state.user.id) {
          this.commit('explodeConfetti')
          this.commit('getBalance')
          this.commit('offPending', { type: 'boost' })
          if (data.addVip) {
            state.user.isVip = true
          }
          vibrate()
        }
      })

      state.socket.on('daily-paid', (data) => {
        if (+data.userId === state.user.id) {
          this.commit('getBalance')
          this.commit('explodeConfetti')
          this.commit('setIsTonDailyBonusClaimed', true)
          this.commit('offPending', { type: 'tonDaily' })
          vibrate()
        }
      })

      state.socket.on('hugged', (data) => {
        if (+data.userId === state.user.id) {
          this.commit('setHugNoty', data)
        }
      })
    },
    setSetting(state, { setting, value }) {
      state.user[setting] = value
      vibrate()
      axios.post('/user/set-setting', { setting, value })
    },
    showError(state, error) {
      state.errorShow = true
      state.errorMessage = error
    },
    showNoty(state, message) {
      state.notyShow = true
      state.notyMessage = message
    },
    setErrorShow(state, show) {
      state.errorShow = show
    },
    setNotyShow(state, show) {
      state.notyShow = show
    },
    setTonConnectUi(state, tcu) {
      state.tonConnectUI = tcu;
    },
    setWallet(state, wallet) {
      state.user.tonWallet = wallet
    },
    setIsTonDailyBonusClaimed(state, isTonDailyBonusClaimed) {
      state.user.isTonDailyBonusClaimed = isTonDailyBonusClaimed
    },
    async fetchRewards(state) {
      const { data } = await axios.get('/rewards/list')
      state.rewards = data.list
    },
    async exitIntro(state) {
      state.introLayout = false
      router.push('/profile')
      await axios.post('/user/intro/finish')
    },
    async setStep(state, step) {
      state.introStep = step
      if (step !== 0) {
        await axios.post('/user/intro/step', {
          step,
        }).catch(e => console.log(e))
      }
    },
    setSiteLoading(state, loading) {
      state.siteLoading = loading
    },
    setAuthToken(state, token) {
      state.authToken = token
    },
    setUser(state, user) {
      state.user = user
    },
    winCombo(state) {
      state.user.isComboTried = true
      state.user.isComboWon = true
    },
    loseCombo(state) {
      state.user.isComboTried = true
      state.user.isComboWon = false
    },
    setBalance(state, balance) {
      state.user.balance = balance
    },
    setConfig(state, config) {
      state.config = config
    },
    setAdGram(state, adGram) {
      state.adController = adGram
    },
    intro(state, { introStep, introFinished }) {
      state.introLayout = !introFinished
      state.introStep = introStep
      if (!state.introLayout) {
        router.push('/profile')
      }
    },
    async getConfig() {
      const { data } = await axios.get('/global/config').catch(e => {
        setTimeout(() => window.location.reload(), 2000)
      })
      this.commit('setConfig', data)
    },
    async getBalance() {
      const { data } = await axios.get('/user/balance')
      this.commit('setBalance', data.balance)
    },
    async login(state, { initData, startAppValue }) {
      const { data } = await axios.post('/user/auth', {
        telegramInitData: initData,
        referralCode: startAppValue,
      }).catch(e => {
        setTimeout(() => this.commit('login', { initData, startAppValue }), 3000)
      })

      if (data.authToken) {
        this.commit('setAuthToken', data.authToken)
        this.commit('setUser', data.user)

        if (data.user.isAdmin && process.env.NODE_ENV !== 'development') {
          eruda.init();
        }

        this.commit('intro', {
          introStep: data.user.introStep,
          introFinished: data.user.introFinished
        })

        this.commit('initSocketListeners')
        setTimeout(() => this.commit('setSiteLoading', false), 1000)
      }
    },
  },
  actions: {
  },
  modules: {
    feed: feed
  }
})
