<template>
  <div class="loader centered">
    <img src="@/assets/images/hug.png" />
    <span>Loading</span>
    <span class="loader-spinner"></span>
  </div>
</template>

<script>
export default {
  name: 'Loader',
}
</script>

<style scoped lang="scss">
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: #fff;
  flex-direction: column;

  span {
    margin-top: 25px;
    font-weight: 500;
    font-size: 22px;
  }

  .loader-spinner {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #007AFF;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
