<template>
  <div class="combo-page centered column">
    <h4 class="page-title">🍀 DAILY COMBO</h4>
    <div class="reward-info">
      <span>Reward for your level: <b>{{ numberWithCommas($store.getters.currentLevel.comboReward) }} $HUG</b></span>
    </div>

    <div class="combo-checked">
      <div class="combo-cell centered grey" v-for="check in checked" :key="check" @click="remove(check)">
        <img :src="`/combo/${check}.png`" />
      </div>
      <div class="combo-cell centered" v-for="a in 4 - checked.length" :key="a">
        <b>-</b>
      </div>
    </div>

    <span class="description">
      Guess the order of the emojis and win prize
    </span>

    <div class="combo-items">
      <div class="combo-item" v-for="smile in smiles" :key="smile" :class="{ 'checked': this.checked.includes(smile) }"
        @click="check(smile)">
        <img :src="`/combo/${smile}.png`" />
      </div>
    </div>

    <Button color="blue" class="check-btn" @on-click="checkRequest" :disabled="checked.length !== 4"
      v-if="!$store.state.user.isComboTried">Check combo</Button>
    <span class="tomorrow" v-if="$store.state.user.isComboTried && !$store.state.user.isComboWon">You've already tried
      to
      guess combos today, come back tomorrow!</span>
    <span class="tomorrow" v-if="$store.state.user.isComboTried && $store.state.user.isComboWon">🥳 You guessed today's
      combo! Come back tomorrow</span>

    <Transition name="modal-fade">
      <ComboModal v-if="showWonModal" @closeModal="() => showWonModal = false" />
    </Transition>
  </div>
</template>

<script>
import axios from 'axios';
import { numberWithCommas } from '../utility';
import Button from '../components/Button.vue';
import Crown from '../components/Animated/Crown.vue';
import ComboModal from '../components/Modals/ComboModal.vue';

export default {
  name: 'ComboView',
  components: {
    Button,
    Crown,
    ComboModal,
  },
  methods: {
    numberWithCommas,
    check(smile) {
      if (this.checked.includes(smile)) {
        this.checked.splice(this.checked.indexOf(smile), 1)
      } else {
        if (this.checked.length >= 4) {
          return
        }
        this.checked.push(smile)
      }
    },
    remove(smile) {
      this.checked.splice(this.checked.indexOf(smile), 1)
    },
    checkRequest() {
      if (this.checked.length !== 4) {
        return
      }

      axios.post('/user/combo', { combo: this.checked }).then(({ data }) => {
        if (data.won) {
          this.$store.commit('winCombo')
          this.$store.commit('explodeConfetti')
          this.$store.commit('getBalance')
          this.showWonModal = true
        } else {
          this.$store.commit('showError', 'You didn\'t get it right this time... come back tomorrow!')
          this.$store.commit('loseCombo')
        }
      }).catch(e => {
        this.$store.commit('showError', 'Some error.. try later')
      })
    }
  },
  data() {
    return {
      showWonModal: false,
      checked: [],
      smiles: ['angry', 'cool', 'disguised', 'evil', 'fear', 'fire', 'ghost', 'heart', 'hug', 'love', 'monkey', 'think']
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.combo-page {
  .page-title {
    font-size: 24px;
    margin: 10px 0;

    @media (max-width: 386px) {
      font-size: 26px;
    }
  }

  .check-btn {
    padding: 20px 10px;
    font-size: 20px;
  }

  .description {
    display: block;
    font-size: 14px;
    color: #656565;
    margin: 10px 0;
  }

  .reward-info {
    display: inline-flex;
    padding: 10px 15px;
    border-radius: 15px;
    background: #e0ffd7;
    text-align: center;
    margin: 20px 0;
  }

  .combo-checked {
    display: flex;
    width: 100%;
    border: 3px solid rgb(160, 160, 160);
    border-radius: 10px;

    .combo-cell {
      display: flex;
      width: 25%;
      height: 96px;
      border-right: 3px solid rgb(160, 160, 160);
      padding: 10px;
      cursor: pointer;

      b {
        color: #cbcbcb;
      }

      img {
        border-radius: 10px;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  .combo-items {
    display: grid;
    grid-template-columns: 64px 64px 64px 64px;
    grid-template-rows: 64px 64px 64px;
    justify-content: center;
    margin: 20px 0;

    .combo-item {
      cursor: pointer;

      img {
        border-radius: 10px;
        border: 3px solid transparent;
        transition: all 0.15s ease-in-out;
      }

      &.checked {
        img {
          border: 3px solid #007aff;
        }
      }
    }
  }
}
</style>