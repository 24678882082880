<template>
  <div class="king-page centered column">
    <div class="king-place">
      <div class="king-info" v-if="currentKing">
        <div @click="$store.commit('showGuest', currentKing.userId)" class="avatar flex centered"
          :style="{ 'background': currentKing.avatarColor }">{{ currentKing.avatarIcon
          }} <img :src="`https://t.me/i/userpic/160/${currentKing.telegramLogin}.jpg`"
            v-if="currentKing.telegramLogin" /></div>
        <Name :username="currentKing.username" :isVip="currentKing.isVip" />
        <span class="earned">Earned {{ numberWithCommas(currentKing.earned) }}
          $HUG</span>
        <span class="time">King Time: <b>{{ currentTimer }}</b></span>
      </div>

      <Crown class="crown" style="pointer-events: none;" />

      <div class="king-info" v-if="!currentKing">
        <div class="avatar flex centered" style="background: #fff">-</div>
        <span class="earned e-free">The place is free!</span>
      </div>
    </div>

    <!-- <div class="action-row">
      <Button color="black" class="join-btn me-king" v-if="currentKing && currentKing.userId === $store.state.user.id">
        <div class="wave"></div>
        <span class="king-txt">👑 You are king</span>
      </Button>
      <Button color="blue" class="join-btn" :disabled="$store.getters.isKingPending" @on-click="join" v-else>
        <template v-if="!$store.getters.isKingPending">
          👑 Take place
        </template>
        <template v-else>
          Transaction processing... (~20s)
        </template>
      </Button>
      <div class="fire-wrapper centered" :class="{ 'clicked': isFireClicked }" @click="fire" v-if="currentKing">
        <Fire ref="fireComp" />
        <span class="value">{{ currentKing.fires }}</span>
      </div>
    </div> -->

    <h4 class="event-end">King event is end!</h4>

    <!-- <span class="description">
      King of the Mountain gets<br />
      <b>{{ $store.state.config.mountainKingDefaultEarned }} $HUG</b> bonus start and <b>bonus from level per
        minute</b>.
      <br />He can be replaced by other players at any time.
      <br /><br />Transactions are processed depending on the load on the blockchain, it usually takes 8-15 seconds
    </span> -->

    <span class="separator">
      <span class="left">Last 10 kings</span>
    </span>

    <div class="places">
      <div class="place flex row pointer" v-for="(member, i) in lastKings" :key="i"
        @click="$store.commit('showGuest', member.userId)">
        <div class="user-info centered">
          <Avatar :avatarColor="member.avatarColor" :avatarIcon="member.avatarIcon"
            :telegramLogin="member.telegramLogin" :level="member.level" />
          <div class="info">
            <Name :username="member.username" :isVip="member.isVip" />
            <span class="balance">+{{ numberWithCommas(+member.earned) }} $HUG
              <span class="finish-time">[{{ calculateTimer(member.createdAt, member.finishedAt) }}]</span>
            </span>
          </div>
        </div>
      </div>
    </div>

    <Transition name="modal-fade">
      <LostKingModal v-if="showLostKingModal" @closeModal="showLostKingModal = false" :earned="earned" />
    </Transition>
  </div>
</template>

<script>
import axios from 'axios';
import { numberWithCommas } from '../utility';
import { beginCell, toNano } from '@ton/ton'

import Name from '@/components/Name.vue';
import Avatar from '@/components/Avatar.vue';
import Button from '../components/Button.vue';
import Crown from '../components/Animated/Crown.vue';
import Fire from '../components/Animated/Fire.vue';
import LostKingModal from '../components/Modals/LostKingModal.vue';

import { vibrate } from '../utility'

export default {
  name: 'KingView',
  components: {
    Button,
    Crown,
    Fire,
    LostKingModal,
    Avatar,
    Name,
  },
  computed: {
    currentTimer() {
      if (!this.currentKing) {
        return '00:00'
      }

      const start = new Date(this.currentKing.createdAt).getTime() / 1000
      const now = this.$store.state.time
      return this.toTimer(now - start)
    }
  },
  methods: {
    numberWithCommas,
    calculateTimer(createdAt, finishedAt) {
      const start = new Date(createdAt).getTime() / 1000
      const now = new Date(finishedAt).getTime() / 1000
      return this.toTimer(now - start)
    },
    async fetchLast() {
      const { data } = await axios.get('/king/list')
      this.lastKings = data.list
    },
    async fetchCurrent() {
      const { data } = await axios.get('/king/current')
      if (
        this.currentKing
        && this.currentKing.userId === this.$store.state.user.id
        && data.currentKing
        && data.currentKing.userId !== this.$store.state.user.id
      ) {
        this.earned = this.currentKing.earned
        this.showLostKingModal = true
        this.$store.commit('getBalance')
        vibrate();
      }
      if (
        this.currentKing
        && this.currentKing.userId !== this.$store.state.user.id
        && data.currentKing
        && data.currentKing.userId === this.$store.state.user.id
      ) {
        this.$store.commit('offPending', { type: 'mountainKing' })
        this.$store.commit('explodeConfetti')
        vibrate();
      }
      if (!this.currentKing && data.currentKing) {
        this.isFireClicked = false
      }
      if (this.currentKing && data.currentKing && this.currentKing.userId !== data.currentKing.userId) {
        this.isFireClicked = false
      }
      this.currentKing = data.currentKing
    },
    async fire() {
      if (this.isFireClicked) {
        return;
      }

      this.$refs.fireComp.onClickFire();
      this.isFireClicked = true;
      vibrate();

      axios.get('/king/fire')
    },
    async update() {
      if (!this.currentKing) {
        return
      }

      const { mountainGPM } = this.$store.state.config.levels.find(l => l.level === this.currentKing.level)
      this.currentKing.earned += mountainGPM
    },
    async addFire() {
      if (!this.currentKing) {
        return
      }

      this.currentKing.fires++
    },
    async join() {
      this.$store.commit('onPending', { type: 'mountainKing' })
      if (!this.$store.state.tonConnectUI.connected) {
        await this.$store.state.tonConnectUI.connectWallet();
      }

      const body = beginCell()
        .storeUint(0, 32)
        .storeStringTail('mountain:' + this.$store.state.user.id.toString())
        .endCell();

      const destination = this.$store.state.config.invoiceTonWallet
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: destination,
            amount: toNano(this.$store.state.config.invoiceList['mountain'].price).toString(),
            payload: body.toBoc().toString("base64")
          }
        ]
      }

      const createdTransaction = await this.$store.state.tonConnectUI.sendTransaction(transaction, {
        modals: "all",
        notifications: "all",
        returnStrategy: "none"
      }).catch(e => {
        console.log(e)
      })

      if (createdTransaction) {
        console.log(createdTransaction)
      }
    },
    toTimer(sec) {
      var sec_num = parseInt(sec, 10);
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours < 10) { hours = "0" + hours; }
      if (minutes < 10) { minutes = "0" + minutes; }
      if (seconds < 10) { seconds = "0" + seconds; }
      return minutes + ':' + seconds;
    },
    listenSocket() {
      this.$store.state.socket.on('king-new', feed => {
        this.fetchCurrent()
        this.fetchLast()
      })
      this.$store.state.socket.on('king-fired', feed => {
        this.addFire()
      })
    },
    unlistenSocket() {
      this.$store.state.socket.off('king-new')
      this.$store.state.socket.off('king-fired')
    },
  },
  data() {
    return {
      showLostKingModal: false,
      earned: 0,
      currentKing: null,
      lastKings: [],
      interval: null,
      isFireClicked: false,
    }
  },
  mounted() {
    this.fetchCurrent()
    this.fetchLast()
    this.interval = setInterval(() => this.update(), 1000)
    this.listenSocket()
  },
  unmounted() {
    if (this.interval) {
      clearInterval(this.interval)
    }
    this.unlistenSocket()
  }
}
</script>

<style lang="scss" scoped>
.king-page {
  display: flex;

  .event-end {
    margin: 20px 0;
    font-size: 18px;
  }

  .action-row {
    display: flex;
    width: 100%;
    gap: 0 5px;
    align-items: center;
  }

  .fire-wrapper {
    display: flex;
    width: 46px;
    height: 57px;
    background: #f0f0f0;
    border-radius: 10px;
    cursor: pointer;
    flex-direction: column;
    transition: all 0.15s ease-in-out;

    .value {
      font-size: 14px;
      margin-top: 3px;
      font-weight: 500;
    }

    &.clicked {
      background: #ffc4c4;
    }
  }

  .join-btn {
    width: 100%;
    margin: 20px 0;
    font-size: 16px;
    padding: 15px 10px;

    &.me-king {
      position: relative;
      overflow: hidden;

      .wave {
        width: 100%;
        height: 200px;
        background-color: #444857;
        box-shadow: inset 0 0 50px rgba(0, 0, 0, .5);
        position: absolute;
        left: 0;
        top: -80px;
        transition: 0.4s;

        &::before,
        &::after {
          width: 200%;
          height: 200%;
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -75%);
        }

        &::before {
          border-radius: 45%;
          background-color: rgb(96, 65, 233);
          animation: wave 10s linear infinite;
        }

        &::after {
          border-radius: 40%;
          background-color: rgba(77, 94, 190, 0.5);
          animation: wave 20s linear infinite;
        }
      }

      .king-txt {
        color: #fff;
        z-index: 2;
      }
    }
  }


  @keyframes wave {
    0% {
      transform: translate(-50%, -75%) rotate(0deg);
    }

    100% {
      transform: translate(-50%, -75%) rotate(360deg);
    }
  }


  .page-title {
    font-size: 24px;
    margin-bottom: 10px;

    @media (max-width: 386px) {
      font-size: 26px;
    }
  }

  .description {
    color: #4F4F4F;
    font-size: 12px;

    .blue {
      color: #007AFF;
      font-weight: 600;
    }
  }

  .king-place {
    position: relative;
    display: flex;
    width: 100%;
    height: 310px;
    flex-direction: column;
    background: #fff3e5;
    border-radius: 15px;
    transition: all 1s ease-in-out;

    .king-info {
      position: absolute;
      width: 100%;
      top: 100px;
      left: 2px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        position: relative;
        animation: bubble 3s infinite;

        @keyframes bubble {
          from {
            box-shadow: 0 0 20px #ffc82300;
          }

          50% {
            box-shadow: 0 0 20px #ffc823;
          }

          to {
            box-shadow: 0 0 20px #ffc82300;
          }
        }


        img {
          max-width: 100px;
          position: absolute;
          border-radius: 50%;
        }
      }

      .name {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 700;
        color: #000000;
      }

      .earned {
        margin-top: 5px;
        font-size: 20px;
        font-weight: 700;
        color: #007AFF;

        &.e-free {
          margin-top: 20px;
        }
      }

      .time {
        margin-top: 5px;
        color: grey;
        font-size: 14px;
      }
    }
  }

  .separator {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 30px 0 20px 0;
    font-size: 14px;
    padding: 0 10px;

    .right {
      color: #4F4F4F;
    }

    b {
      font-weight: 700;
    }
  }

  .my-place {
    display: flex;
    gap: 20px 0;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    margin-top: 30px;
    background: #F6F6F6;
    border-radius: 10px;
  }

  .places {
    display: flex;
    gap: 20px 0;
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
  }

  .place {
    justify-content: space-between;

    .user-info {
      display: flex;

      .avatar {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        position: relative;

        img {
          max-width: 46px;
          position: absolute;
          border-radius: 50%;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 10px;

        .name {
          font-size: 16px;
          margin-bottom: 5px;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 200px;
          white-space: nowrap;
          display: flex;
          align-items: center;

          &.vip-name {
            color: #fbc751;
            font-weight: 600;
          }

          img {
            width: 21px;
          }
        }

        .vip {
          margin-right: 3px;
        }

        .balance {
          font-weight: 600;
          color: #007AFF;

          .finish-time {
            font-weight: 400;
            font-size: 12px;
            color: #616161;
          }
        }
      }
    }
  }
}
</style>
