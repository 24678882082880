<template>
  <Modal>
    <TelegramStar class="star" />

    <div class="modal-header">
      <h4 class="title">Boost $HUG</h4>
      <span class="description flex centered">
        <img src="@/assets/images/yellow-star.svg" /> - grants you VIP status
      </span>
      <div class="close centered" @click="$emit('closeModal')">X</div>
    </div>

    <div class="items">
      <div class="item" v-for="item in $store.getters.tonBoostList"
        :class="{ 'active': selectedItem.amount === item.amount }" @click="selectedItem = item" :key="item.id">
        <img src="@/assets/images/ton.svg" />
        <span class="old">{{ numberWithCommas(Math.floor(item.amount - (item.amount / 2))) }}</span>
        <span class="new">{{ numberWithCommas(item.amount) }} $HUG <span class="vip" v-if="item.addVip">+ <img
              src="@/assets/images/yellow-star.svg" /></span></span>
      </div>
    </div>

    <Button color="blue" class="buy-btn" @on-click="buy">
      <template v-if="!$store.getters.isBoostPending">
        Confirm and Pay <img src="@/assets/images/ton.svg" />{{ selectedItem.price.toFixed(2) }}
      </template>
      <template v-else>
        Transaction processing... (~20s)
      </template>
    </Button>
  </Modal>
</template>

<script>
import { beginCell, toNano } from '@ton/ton'
import { numberWithCommas } from '@/utility';
import Modal from '@/components/Modals/Modal.vue'
import Button from '@/components/Button.vue'
import TelegramStar from '@/components/Animated/TelegramStar.vue'

export default {
  name: 'BoostModal',
  components: { Modal, Button, TelegramStar },
  methods: {
    numberWithCommas,
    async buy() {
      this.$store.commit('onPending', { type: 'boost' })
      if (!this.$store.state.tonConnectUI.connected) {
        await this.$store.state.tonConnectUI.connectWallet();
      }

      const body = beginCell()
        .storeUint(0, 32)
        .storeStringTail(this.selectedItem.id + ':' + this.$store.state.user.id.toString())
        .endCell();

      const destination = this.$store.state.config.invoiceTonWallet
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: destination,
            amount: toNano(this.selectedItem.price).toString(),
            payload: body.toBoc().toString("base64")
          }
        ]
      }

      const createdTransaction = await this.$store.state.tonConnectUI.sendTransaction(transaction, {
        modals: "all",
        notifications: "all",
        returnStrategy: "none"
      }).catch(e => {
        console.log(e)
      })

      if (createdTransaction) {
        console.log(createdTransaction)
      }
    }
  },
  data() {
    return {
      selectedItem: null,
    }
  },
  mounted() {
    this.selectedItem = this.$store.getters.tonBoostList[1]
  }
}
</script>

<style scoped lang="scss">
.star {
  margin: -30px auto;
}

.description {
  img {
    margin-right: 3px;
  }
}

.items {
  display: flex;
  margin: 10px 0;
  flex-direction: column;
  width: 100%;
  gap: 10px 0;

  .item {
    display: flex;
    padding: 7px 10px;
    border-radius: 10px;
    border: 2px solid transparent;
    transition: all 0.15s ease-in-out;
    align-items: center;
    background: #ececec;

    @media screen and (max-height: 645px) {
      padding: 5px 10px;
    }

    img {
      margin-right: 10px;
      width: 20px;
    }

    .old {
      color: grey;
      text-decoration: line-through;
      margin-right: 15px;
    }

    .new {
      .vip {
        display: inline-flex;
        align-items: center;

        img {
          margin-left: 3px;
        }
      }
    }

    &.active {
      border: 2px solid #007AFF;
    }
  }
}

.buy-btn {
  margin-top: 20px;
  flex-direction: row;
  height: 55px;
  font-size: 18px;

  img {
    margin: 0 5px 0 8px;
    width: 24px;
    border: 1px solid white;
    border-radius: 50%;
  }

  @media screen and (max-height: 645px) {
    margin-top: 10px;
  }
}
</style>
