<template>
  <Modal class="how-modal">
    <TelegramStar class="star" />

    <div class="modal-header centered">
      <h4 class="title">How to Hug? 🤗</h4>
      <span class="description">To hug another person go to their profile<br />and click on "Send a HUG"<br />
        You can send a hug and get reward every 3 hours</span>
      <img src="@/assets/images/how-select.jpg" class="how-img" />
      <img src="@/assets/images/how-to-hug.jpg" class="how-img" />
      <div class="close centered" @click="$emit('closeModal')">X</div>
    </div>

    <Button class="close-btn" @on-click="() => $emit('closeModal')">Close</Button>
  </Modal>
</template>

<script>
import Modal from './Modal.vue'
import Button from '../Button.vue'

export default {
  name: 'HowHugModal',
  components: { Modal, Button },
  methods: {},
  data() {
    return {
      selectedItem: null,
    }
  },
}
</script>

<style scoped lang="scss">
.how-modal {
  .modal-header {
    display: flex;
    flex-direction: column;


    .description {
      font-size: 14px;
    }
  }

  img {
    max-width: 50%;
    margin: 5px 0;
  }

  .how-img {
    border: 2px solid grey;
    border-radius: 10px;
  }

  .how-row {
    gap: 0 10px;
    margin: 10px 0;
  }
}
</style>
