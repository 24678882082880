<template>
  <Modal class="combo-modal">
    <TelegramStar class="star" />

    <div class="modal-header centered">
      <h4 class="title">Congratulations!</h4>
      <span class="description">🥳 You guessed today's
        combo! Come back tomorrow</span>

      <div class="close centered" @click="$emit('closeModal')">X</div>
    </div>

    <Button class="close-btn" @on-click="() => $emit('closeModal')">Thanks!</Button>
  </Modal>
</template>

<script>
import Modal from './Modal.vue'
import Button from '../Button.vue'

export default {
  name: 'ComboModal',
  components: { Modal, Button },
  methods: {},
  data() {
    return {
      selectedItem: null,
    }
  },
}
</script>

<style scoped lang="scss">
.combo-modal {
  .modal-header {
    display: flex;
    flex-direction: column;


    .description {
      font-size: 14px;
    }
  }
}
</style>
