import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LeaderboardView from '../views/LeaderboardView.vue'
import FriendsView from '../views/FriendsView.vue'
import TasksView from '../views/TasksView.vue'
import KingView from '../views/KingView.vue'
import AdminView from '../views/AdminView.vue'
import ProfileView from '../views/ProfileView.vue'
import RaceView from '../views/RaceView.vue'
import ComboView from '../views/ComboView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: LeaderboardView
  },
  {
    path: '/friends',
    name: 'friends',
    component: FriendsView
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: TasksView
  },
  {
    path: '/king',
    name: 'king',
    component: KingView
  },
  {
    path: '/race',
    name: 'race',
    component: RaceView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/combo',
    name: 'combo',
    component: ComboView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // const viewport = document.getElementById("viewport");
    // if (viewport) {
    //   viewport.scrollTo(0, 0);
    // }
    try {
      window.scrollTo(0, 0)
    } catch (e) {

    }
  }
})

export default router
