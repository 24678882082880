<template>
  <header class="centered">
    <Avatar :avatarColor="$store.state.user.avatarColor" :avatarIcon="$store.state.user.avatarIcon"
      :telegramLogin="$store.state.user.telegramLogin" :level="$store.state.user.level" width="120" height="120"
      levelBig="true" />
    <span class="balance">{{ numberWithCommas($store.state.user.balance) }} <span class="currency">$HUG</span></span>
    <Button color="blue" class="boost-btn" @on-click="() => $store.commit('showBoostModal', true)"><img
        src="@/assets/images/yellow-star.svg" />
      Boost $HUG</Button>
  </header>
</template>

<script>
import { numberWithCommas } from '@/utility';
import Avatar from '@/components/Avatar.vue';
import Button from '@/components/Button.vue';

export default {
  name: 'Header',
  components: { Avatar, Button },
  methods: { numberWithCommas }
}
</script>

<style scoped lang="scss">
header {
  display: flex;
  flex-direction: column;
  gap: 15px 0;

  img {
    width: 150px;
  }

  .boost-btn {
    flex-direction: row;

    img {
      width: 20px;
      margin-right: 5px;
    }
  }

  .welcome {
    font-size: 16px;
    color: #4F4F4F;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 280px;
    white-space: nowrap;
  }

  .balance {
    color: #000;
    font-size: 36px;
    font-weight: 700;

    .currency {
      font-weight: 400;
      font-size: 20px;
      color: grey;
    }
  }
}
</style>
