<template>
  <Modal>
    <div class="modal-header">
      <h4 class="title">Your TON wallet</h4>
      <span class="description">{{ $store.state.user.tonWallet }}</span>
      <div class="close centered" @click="$emit('closeModal')">X</div>
    </div>

    <Button class="action-btn" @on-click="disconnect">Disconnect</Button>
    <Button class="action-btn" @on-click="() => $emit('closeModal')">Close</Button>
  </Modal>
</template>

<script>
import axios from "axios";

import Modal from './Modal.vue'
import Button from '../Button.vue'

export default {
  name: 'WalletModal',
  components: { Modal, Button },
  methods: {
    async disconnect() {
      try {
        await axios.post('/user/disconnect-ton-wallet');
        this.$store.commit('setWallet', null)
        this.$emit('closeModal')
      } catch (error) {
        this.$store.commit('showError', error.response.data.message)
      }
    }
  },
  data() {
    return {
      selectedItem: null,
    }
  },
}
</script>

<style scoped lang="scss">
.modal-header {
  .description {
    font-size: 12px;
  }
}

.action-btn {
  margin: 10px 0;
}
</style>
