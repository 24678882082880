<template>
  <div class="level-info">
    <div class="levels">
      <div class="level">
        <div class="flex row centered">
          <div class="step flex centered">{{ currentLevel.level }}</div>
        </div>
        <h3 v-if="nextLevel">Your level</h3>
        <h3 v-else>You have reached the maximum level!</h3>
        <p><img src="@/assets/images/icons/hug.svg" alt="Hug Icon">{{ currentLevel.hugReward }} $HUG from Send a Hug</p>
        <p><img src="@/assets/images/icons/king.svg" alt="Crown Icon">{{ currentLevel.mountainGPM }} $HUG/sec from
          Mountain King</p>
        <p><img src="@/assets/images/icons/clover.png" alt="Clover Icon">{{ currentLevel.comboReward }} $HUG from Combo
        </p>
      </div>
      <div class="separator" v-if="nextLevel"></div>
      <div class="level next" v-if="nextLevel">
        <div class="flex row centered">
          <div class="step flex centered">{{ nextLevel.level }}</div>
        </div>
        <h3>Next level</h3>
        <p><img src="@/assets/images/icons/hug.svg" alt="Hug Icon">{{ nextLevel.hugReward }} $HUG from Send a Hug</p>
        <p><img src="@/assets/images/icons/king.svg" alt="Crown Icon">{{ nextLevel.mountainGPM }} $HUG/sec from Mountain
          King</p>
        <p><img src="@/assets/images/icons/clover.png" alt="Clover Icon">{{ nextLevel.comboReward }} $HUG from Combo</p>
      </div>
    </div>
    <div class="action-row">
      <Button color="orange" class="upgrade-btn" v-if="nextLevel" @on-click="() => $emit('levelUp')">Upgrade ({{
        nextLevel.cost }} $HUG)</Button>
      <Button color="black" class="how-btn" @on-click="$store.commit('showHowHugModal', true)"
        :class="{ 'full': !nextLevel }">How to hug?</Button>
    </div>

  </div>
</template>

<script>
import Button from '../components/Button.vue';

export default {
  components: { Button },
  props: ['currentLevel', 'nextLevel'],
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">
.level-info {

  .action-row {
    display: flex;
    gap: 0 5px;
    width: 100%;
    align-items: center;
    margin-top: 10px;
    flex-direction: column;

    .btn {
      width: 100%;
    }

    .how-btn {
      width: 100px;
      padding: 5px 5px;
      height: 51px;
      margin-top: 5px;
      font-size: 14px;
      width: 100%;
    }
  }

  .upgrade-btn {
    padding: 15px 10px;
    font-size: 16px;
  }

  .levels {
    display: flex;
    justify-content: space-between;
    background: #F6F6F6;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px 15px;
    position: relative;


    .level {
      .step {
        display: flex;
        width: 40px;
        height: 40px;
        background: rgb(255, 255, 255);
        border-radius: 50%;
        margin-bottom: 10px;
        font-weight: 700;
      }

      flex: 1;
      text-align: left;

      h3 {
        margin-bottom: 15px;
        font-size: 16px;
        text-align: center;
        color: #737373;
      }

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
        font-weight: 600;
        font-size: 14px;

        img {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }

      &.next {
        p {
          color: #2865A8
        }
      }
    }

    .separator {
      width: 1px;
      background-color: #e0e0e0;
      position: relative;
      margin: 0 20px;
    }
  }
}
</style>