<template>
  <div class="friends centered column">

    <FriendHug />

    <h4 class="page-title">Invite friends</h4>
    <span class="description">Send a hug to your friends and get more $HUG tokens</span>

    <Button color="blue" class="invite-btn" @on-click="invite">Invite friend</Button>

    <div class="hugs" v-if="$store.state.user.referralTotalInvite > 0">
      <span class="title">
        You sent <b>{{ $store.state.user.referralTotalInvite }} hug(s)</b> and got <b>{{
          numberWithCommas(+$store.state.user.referralTotalEarn) }} $HUG</b>!
      </span>
    </div>

    <h4 class="benefits-title">Your bonuses 😋</h4>
    <div class="benefits">
      <div class="benefit-item">
        <div class="icon">⭐️</div>
        <div class="info">
          <span class="name">Boost</span>
          <span class="desc">You will get <b>5%</b> for every referral purchase</span>
        </div>
      </div>
      <div class="benefit-item">
        <div class="icon">👑</div>
        <div class="info">
          <span class="name">Mountain King wins</span>
          <span class="desc">You will get <b>5%</b> for every referral winnings at Mountain King</span>
        </div>
      </div>
      <div class="benefit-item">
        <div class="icon">🌟</div>
        <div class="info">
          <span class="name">Telegram Premium Bonus</span>
          <span class="desc">Friend with Telegram Premium gets an extra <b>$2,500 HUG</b>, and you get <b>$500
              HUG</b></span>
        </div>
      </div>
      <div class="benefit-item">
        <div class="icon">🫂</div>
        <div class="info">
          <span class="name">Bonus from activity</span>
          <span class="desc">You will get <b>10%</b> from referral welcome bonus</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { numberWithCommas } from '../utility';
import Button from '../components/Button.vue';
import FriendHug from '../components/Animated/FriendHug.vue';

export default {
  name: 'FriendsView',
  components: {
    Button,
    FriendHug
  },
  methods: {
    numberWithCommas,
    invite() {
      const message = `Let's have a hug ❤️`
      const refLink = this.$store.state.config.telegramBotLinkJoin.replace('https://', '') + '?startapp=' + this.$store.state.user.referralCode
      window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${refLink}&text=${message}`)
    }
  },
  data() {
    return {

    }
  },
}
</script>

<style lang="scss" scoped>
.friends {
  display: flex;

  .page-title {
    font-size: 24px;
    margin: 10px 0;

    @media (max-width: 386px) {
      font-size: 20px;
    }
  }

  .share {
    width: 100%;
    max-width: 100px;
  }

  .description {
    font-size: 14px;
    color: #656565;
  }

  .invite-btn {
    width: 100%;
    padding: 15px 25px;
    font-size: 18px;
    margin: 20px 0 20px 0
  }

  .benefits-title {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    margin: 10px 0;
    color: #656565;
    width: 100%;
    padding-left: 10px;
  }

  .benefits {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px 0;
    padding: 10px;
    background: #f0f0f0;
    border-radius: 10px;

    .benefit-item {
      display: flex;
      width: 100%;
      align-items: center;
      background: #fff;
      border-radius: 10px;
      padding: 10px 15px;

      .icon {
        display: flex;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #EDEDED;
        align-items: center;
        justify-content: center;
        flex: 0 0 32px;
      }

      .info {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 10px;

        .name {
          font-size: 16px;
          font-weight: 600;

          @media (max-width: 364px) {
            font-size: 14px;
          }

        }

        .desc {
          color: #656565;
          margin-top: 5px;
          font-size: 14px;

          @media (max-width: 364px) {
            font-size: 12px;
          }

        }
      }
    }
  }

  .hugs {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    border: 2px solid #f0f0f0;
    padding: 10px 0;
    border-radius: 10px;
    font-size: 14px;

    .list {
      display: flex;
      width: 100%;
      gap: 10px 0;
      margin-top: 20px;
      flex-direction: column;

      .item {
        justify-content: space-between;

        .user-info {
          display: flex;

          .avatar {
            width: 46px;
            height: 46px;
            border-radius: 50%;
            position: relative;

            img {
              max-width: 46px;
              position: absolute;
              border-radius: 50%;
            }
          }

          .info {
            display: flex;
            flex-direction: column;
            text-align: left;
            margin-left: 10px;

            .name {
              font-size: 16px;
              margin-bottom: 5px;
            }
          }
        }

        .reward {
          font-weight: 600;
          width: 100%;
          justify-content: flex-end;
        }
      }
    }
  }
}
</style>