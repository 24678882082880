<template>
  <div class="community centered">
    <span class="title">🫂 HUG community</span>
    <span class="description">Stay updated with the latest news</span>
    <Button class="join-btn" @on-click="() => join()">Join community</Button>
  </div>
</template>

<script>
import Button from './Button.vue'

export default {
  name: 'Community',
  components: { Button },
  props: ['isCommunityMember'],
  methods: {
    join() {
      if (process.env.NODE_ENV === 'development') {
        return
      }

      window.Telegram.WebApp.openTelegramLink(this.$store.state.config.telegramCommunityLink)
    }
  }
}
</script>

<style scoped lang="scss">
.community {
  display: flex;
  flex-direction: column;
  gap: 15px 0;
  background: #EDEDED;
  padding: 15px 20px;
  border-radius: 10px;
  width: 100%;
  margin: 20px 0;

  .title {
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }

  .description {
    color: #4F4F4F;
    font-size: 14px;

    .blue {
      color: #007AFF;
    }

    @media (max-width: 386px) {
      font-size: 14px;
    }
  }

  .join-btn {
    max-width: 200px;
    width: 100%;
  }
}
</style>
