<template>
  <div class="admin-panel centered column">
    <h4 class="page-title">⚙️ Admin panel</h4>

    <h4 class="table-title">Stats</h4>
    <div class="table">
      <div class="flex row" v-for="key in keys" :key="key">
        <span class="name">{{ getKeyName(key) }}</span>
        <span class="value">{{ stats[key] || 0 }}</span>
      </div>
    </div>

    <h4 class="table-title">Users timeline</h4>
    <div class="table">
      <div class="flex row" v-for="key in timelineUsers" :key="key">
        <span class="name">{{ key.registrationDate }}</span>
        <span class="value">+{{ key.userRegistrations }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { numberWithCommas } from '../utility';
import Button from '../components/Button.vue';
import axios from 'axios';

export default {
  name: 'AdminView',
  components: {
    Button
  },
  methods: {
    numberWithCommas,
    async fetchStats() {
      const { data } = await axios.get('/admin/stats')
      this.stats = data.stats
      this.keys = Object.keys(data.stats)
    },
    async fetchTimelineUsers() {
      const { data } = await axios.get('/admin/timeline/users')
      this.timelineUsers = data.timeline
    },
    getKeyName(key) {
      if (key === 'usersCount') {
        return 'Total users'
      } else if (key === 'usersRegsTodayCount') {
        return 'Registrations today'
      } else if (key === 'usersLoggedTodayCount') {
        return 'Users logged today'
      } else if (key === 'tonTransactionsToday') {
        return 'Ton transaction today'
      } else if (key === 'usersWithTonWallet') {
        return 'Users with Ton Wallet'
      } else if (key === 'usersWithReferrals') {
        return 'Users with any referrals'
      } else if (key === 'usersWithTelegramPremium') {
        return 'Users with TG Premium'
      } else if (key === 'usersCompleteDailyTask') {
        return 'Users who complete daily task'
      } else if (key === 'usersVip') {
        return 'Users VIP'
      } else if (key === 'usersIntroStep') {
        return 'Users not complete intro'
      } else if (key === 'usersSumBalanceActive') {
        return 'Users sum balance (active)'
      } else if (key === 'tonTransactionsSumToday') {
        return 'Transactions sum today (in TON)'
      }
    }
  },
  data() {
    return {
      stats: {},
      keys: [],

      timelineUsers: []
    }
  },
  mounted() {
    this.fetchStats()
    this.fetchTimelineUsers()
  }
}
</script>

<style lang="scss" scoped>
.admin-panel {
  display: flex;

  .page-title {
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 20px;

    @media (max-width: 386px) {
      font-size: 26px;
    }
  }

  .table-title {
    font-size: 20px;
    margin: 10px 0;
    width: 100%;
    text-align: left;
  }

  .table {
    display: flex;
    width: 100%;
    border-radius: 10px;
    background: #d6d6d6;
    gap: 10px 0;
    flex-direction: column;
    padding: 10px;

    .row {
      background: white;
      border: 1px solid #ababab;
      border-radius: 10px;
      justify-content: space-between;
      padding: 10px 15px;
      align-items: center;

      .name {
        color: #323232;
        font-size: 12px;
      }

      .value {
        font-size: 18px;
      }
    }
  }
}
</style>