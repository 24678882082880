<template>
  <Vue3Lottie :animationData="animation" :height="24" :width="24" :loop="1" ref="anim" />
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import animation from '@/assets/images/fire.json'

export default {
  components: {
    Vue3Lottie,
  },
  data() {
    return {
      animation,
    }
  },
  methods: {
    onClickFire() {
      this.$refs.anim.goToAndPlay(0)
    }
  }
}
</script>