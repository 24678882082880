<template>
  <div class="settings">
    <div class="setting">
      <span>TON wallet</span>
      <TonWallet />
    </div>
    <div class="setting">
      <span>Turn off bot notifications</span>
      <label class="switch">
        <input type="checkbox" :checked="$store.state.user.isBotNotificationsDisabled"
          @change="onChange($event, 'isBotNotificationsDisabled')">
        <span class="slider round"></span>
      </label>
    </div>
    <div class="setting">
      <span>Turn off HUG notifications</span>
      <label class="switch">
        <input type="checkbox" :checked="$store.state.user.isHugNotificationsDisabled"
          @change="onChange($event, 'isHugNotificationsDisabled')">
        <span class="slider round"></span>
      </label>
    </div>
    <div class="setting">
      <span>Hide your Telegram profile</span>
      <label class="switch">
        <input type="checkbox" :checked="$store.state.user.isTelegramHidden"
          @change="onChange($event, 'isTelegramHidden')">
        <span class="slider round"></span>
      </label>
    </div>
  </div>
</template>

<script>
import TonWallet from './TonWallet.vue';

export default {
  name: 'Settings',
  components: { TonWallet },
  methods: {
    onChange(e, setting) {
      this.$store.commit('setSetting', { setting, value: !this.$store.state.user[setting] });
    },
  }
}
</script>

<style lang="scss">
.settings {
  background: #f8f9fa;
  border-radius: 10px;
  padding: 15px;
  max-width: 400px;
  margin: 20px auto;
  width: 100%;

  .setting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #e0e0e0;
    font-weight: 600;

    &:last-child {
      border-bottom: none;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: 0.4s;
      border-radius: 34px;

      &.round {
        border-radius: 34px;
      }

      &:before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    input:checked+.slider {
      background-color: #2865A8;
    }

    input:checked+.slider:before {
      transform: translateX(14px);
    }
  }
}
</style>
