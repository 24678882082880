<template>
  <div class="leaderboard centered">
    <!-- <div class="tabs">
      <Button :color="tab === 'main' ? 'blue' : 'black'" :class="tab === 'main' ? 'active' : ''"
        @click="tab = 'main'">🏆
        Leaderboard</Button>
      <Button :color="tab === 'race' ? 'blue' : 'black'" :class="tab === 'race' ? 'active' : ''"
        @click="tab = 'race'">🏁
        Race</Button>
    </div> -->

    <LeaderboardMain />
  </div>
</template>

<script>
import { numberWithCommas } from '@/utility';
import Skeleton from "@/components/Skeleton.vue";
import Button from "@/components/Button.vue";
import LeaderboardMain from "@/components/Leaderboard/LeaderboardMain.vue";
import LeaderboardRace from "@/components/Leaderboard/LeaderboardRace.vue";
import BoostModal from "@/components/Modals/BoostModal.vue";

export default {
  name: 'LeaderboardView',
  components: {
    Skeleton,
    Button,
    BoostModal,
    LeaderboardMain,
    LeaderboardRace,
  },
  methods: {
    numberWithCommas,
    async boost() {
      this.$store.commit('showBoostModal', true)
    }
  },
  data() {
    return {
      tab: 'main'
    }
  },
}
</script>

<style lang="scss" scoped>
.boost-btn {
  margin-top: 15px;
  flex-direction: row;

  img {
    margin-right: 5px;
  }
}

.leaderboard {
  .tabs {
    display: flex;
    margin-bottom: 10px;

    .btn {
      width: 100%;
      font-size: 14px;
      border-radius: 0;
      background: none;
      color: black;
      border-bottom: 2px solid #cacaca;

      &.active {
        border-bottom: 2px solid #007AFF;
      }
    }
  }
}
</style>