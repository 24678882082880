<template>
  <div class="profile-page column">
    <Feed v-if="$store.state.feed.lastFeed" />

    <Bonus v-if="!$store.state.user.isGiftClaimed" />

    <Header />

    <div class="level-wrapper">
      <Level :currentLevel="$store.getters.currentLevel" :nextLevel="$store.getters.nextLevel" @levelUp="levelUp" />
    </div>

    <!-- <Community /> -->

    <div class="settings-wrapper">
      <h2>Settings</h2>
      <Settings />
    </div>

    <!-- <div class="rewards-wrapper centered">
      <span class="wrapper-title">Your transactions</span>
      <RewardList :rewards="$store.state.rewards" />
    </div> -->
  </div>
</template>

<script>
import axios from 'axios';
import { numberWithCommas } from '../utility';
import Button from '../components/Button.vue';
import Header from '../components/Header.vue';
import Bonus from '../components/Bonus.vue';
import RewardList from '../components/RewardList.vue';
import Level from '../components/Level.vue';
import Settings from '../components/Settings.vue';
import Feed from '../components/Feed.vue';
import Community from '../components/Community.vue';

export default {
  name: 'ProfileView',
  components: {
    Button,
    Header,
    Bonus,
    RewardList,
    Level,
    Settings,
    Feed,
    Community,
  },
  methods: {
    numberWithCommas,
    listenSocket() {
      this.$store.state.socket.on('feed', feed => {
        this.$store.commit('feed/setLastFeed', feed)
      })
      this.$store.state.socket.emit('request-feed')
    },
    unlistenSocket() {
      this.$store.state.socket.off('feed')
    },
    levelUp() {
      if (!this.$store.getters.nextLevel) {
        return
      }

      if (+this.$store.state.user.balance < this.$store.getters.nextLevel.cost) {
        this.$store.commit('showError', 'Not enough $HUG')
        return
      }

      axios.post('/user/levelup').then(({ data }) => {
        if (data) {
          this.$store.commit('getBalance')
          this.$store.commit('explodeConfetti')
          this.$store.commit('levelup')
        } else {
          this.$store.commit('showError', 'Not enough $HUG')
        }
      }).catch(() => {
        this.$store.commit('showError', 'Not enough $HUG')
      })
    }
  },
  data() {
    return {

    }
  },
  mounted() {
    this.$store.commit('fetchRewards')
    this.listenSocket()
  },
  beforeUnmount() {
    this.unlistenSocket()
  }
}
</script>

<style lang="scss" scoped>
.profile-page {
  display: flex;
  text-align: center;
  justify-content: center;

  .page-title {
    font-size: 24px;
    margin: 10px 0;

    @media (max-width: 386px) {
      font-size: 20px;
    }
  }

  .share {
    width: 100%;
    max-width: 100px;
  }

  .description {
    font-size: 14px;
    color: #656565;
  }

  .level-wrapper {
    margin: 20px 0;
  }

  .wallet-wrapper {
    margin: 20px 0;
  }

  .settings-wrapper {
    h2 {
      font-weight: 400;
    }

    margin: 20px 0;
  }

  .rewards-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .wrapper-title {
      font-size: 22px;
      margin-bottom: 20px;
    }
  }
}
</style>